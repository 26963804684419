@import '../../styles/colors.scss';
@import '../../styles/media.scss';

.burgerModal {
  overflow: hidden;
  position: fixed !important;
  > div {
    align-items: flex-start;
    overflow: hidden;
    > div {
      overflow: hidden;
      margin: 60px 0 0;
      max-width: auto;
      border-radius: 0;
      border-bottom-right-radius: 12px;
      border-bottom-left-radius: 12px;
    }
  }
  .burgerMenu {
    width: 100%;
    padding: 50px 16px 35px;
    box-sizing: border-box;
    background-color: $white;
    z-index: 1;
    height: auto;

    > div {
      padding: 0;
      .menuTitle {
        margin: 0;
        font-size: 36px;
        line-height: 49px;
        font-family: OpenSansRegular;
        span {
          font-weight: 700;
        }
      }
    }
    > form {
      margin: 30px 0 0;
      .textField {
        width: 100%;
      }
      .inputText {
        font-weight: 600;
        color: $black;
        font-size: 16px;
        margin-left: 9px;
      }
      .inputTextError {
        font-size: 12px;
        color: $red;
        margin-top: 8px;
        margin-left: 10px;
        font-weight: 600;
      }
      .alreadyHaveAccount {
        margin: 39px 8px 17px;
        font-weight: 600;
        color: $black;
        font-size: 16px;
        margin-left: 9px;
      }
      .passwordInfoContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 25px;
      }
      .forgotPasswordButton {
        font-size: 16px;
        font-weight: 600;
        color: $black;
        border-radius: 0;
        padding: 0;
        margin: 0;
        text-decoration: underline;
      }
      .inputFieldWrapper {
        border-width: 1px;
        border-style: solid;
        border-color: $border_inputs;
        border-radius: 12px;
        margin-bottom: 25px;
      }

      .inputFieldWrapperError {
        border-width: 1px;
        border-style: solid;
        border-color: $red;
        border-radius: 12px;
      }

      .inputFieldWrapperSuccess {
        border-width: 1px;
        border-style: solid;
        border-color: $green;
        border-radius: 12px;
        margin-bottom: 25px;
      }
      .passwordButton {
        height: 24px;
        width: 24px;
      }
    }

    .loader {
      position: absolute;
      top: 30%;
      left: 43%;
    }
  }
}
