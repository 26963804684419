@import '../../styles/colors.scss';
@import '../../styles/media.scss';

.main {
  grid-area: m;
  border-radius: 8px;
  padding: 0vw 10vw 3vw 10vw;
  background-color: $bg_color;
  box-sizing: border-box;
  min-height: calc(100vh - 194px);
  > h1 {
    margin: 0 0 50px 16px;
    font-family: 'GothamLight';
    > span {
      font-family: 'GothamMedium';
    }
  }
  .booksList {
    display: flex;
    width: 80vw;
    flex-wrap: wrap;
    box-sizing: border-box;
  }
  .noResult {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    padding-top: 10%;
    font-family: 'GothamMedium';
  }
  .loader {
    position: absolute;
    top: 48%;
    left: 50%;
  }
}

@media screen and (max-width: $break_point_xxlg) {
  .main {
    padding: 0vw 0vw 30px 45px;
    .booksList {
      width: 95vw;
    }
  }
}

@media screen and (max-width: $break_point_xs) {
  .main {
    padding: 60px 0 0;
    > h1 {
      display: none;
    }
    .booksList {
      width: 100%;
      padding-bottom: 80px;
    }
    .loader {
      position: absolute;
      left: 43%;
    }
    .noResult {
      font-size: 20px;
      padding-top: 50%;
    }
  }
}
