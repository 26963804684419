@import '../../styles/colors.scss';
@import '../../styles/media.scss';

.parent {
  position: relative;
  top: 0;
  z-index: 5;
}
.wrapper {
  position: absolute;
  width: 358px;
  bottom: 8.3vh;
  right: 0;

  & > div {
    width: 358px;
    border-radius: 12px;
    box-shadow: var(--pageBoxShadow);
    position: fixed;
    bottom: 140px;
    right: 40px;

    .hoverDiv {
      position: absolute;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      background-color: transparent;
      height: calc(100% + 35px);
      content: '';
      bottom: -35px;
      left: 0;
      width: 100%;
      z-index: 300;
      padding: 16px 0px 14px;
      box-sizing: border-box;
      max-height: 313px;
      max-width: 358px;
      border-radius: 12px;
    }
    iframe {
      position: relative;
      z-index: 1;
      width: 358px;
      height: calc(18.75vw - 20px);
      border-radius: 8px;
      max-height: 313px;
      max-width: 358px;
    }
  }
  .fullScreenSet {
    border-radius: 0px;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    margin-right: 0px;
    transform: translate(0px, 0px) !important;
    .hoverDiv {
      left: calc(50vw - 179px);
      bottom: 0;
    }
    > div {
      iframe {
        width: 100vw;
        height: 100vh;
        border-radius: 0px;
        max-width: none;
        max-height: none;
      }
    }
  }
  .controls {
    bottom: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100%);
    box-sizing: border-box;
    height: 96px;
    background-color: $bg_color_opacity;
    border-radius: 12px;
    box-shadow: 0px 5px 15px 0px #00000026;
    & > div:first-child {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      z-index: 10;
      width: 90%;
      button {
        border-radius: 8px;
        color: #000;
        height: 24px;
        width: 24px;
        margin: 0;
        padding: 0;
        min-width: auto;
        max-height: auto;
        &.playPause {
          width: 48px;
          height: 48px;
          background-color: $black;
        }
        &.rewind span:first-child {
        }
        &.forward span:first-child {
        }
      }
    }
    .progressBar {
      align-self: center;
      padding: 0px;
      width: calc(100% - 32px);
      z-index: 10;
      border-radius: 8px;
      height: 8px;
      margin-top: 10px;
      box-sizing: border-box;
      .progressBarRoot {
        background-color: $black_with_opacity_ten;
        border-radius: 8px;
        height: 8px;
        z-index: 1;
        .progressBarBar {
          background-color: $purple;
          border-radius: 8px;
          z-index: 0;
        }
      }
    }
  }
}

@media screen and (max-width: $break_point_md) {
  .wrapper {
    width: 100vw;
    & > div {
      right: 16px;
      bottom: 120px;

      iframe {
        width: calc(100vw - 32px);
        height: calc(100vw - 32px);
      }
    }
  }
}

@media screen and (max-width: $break_point_sm) {
  .wrapper {
    & > div {
      width: calc(100vw - 32px);
      bottom: 50px;
      .hoverDiv {
        max-width: none;
      }
      iframe {
        margin: 0 0 0 1px;
        width: calc(100vw - 34px);
        max-width: none;
      }
    }
    .fullScreenSet {
      .hoverDiv {
        left: 0;
      }
    }
  }
}
