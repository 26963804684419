$green: #24d399;
$grey: #6c6c6c;
$blue: #0073ff;

@import '../../styles/colors.scss';
@import '../../styles/media.scss';

.wrapper {
  display: grid;
  margin: 0 auto;
  grid-template-areas:
    'h h h'
    'd d f'
    'ff ff ff';
  grid-template-columns: 4fr 4fr 4fr;
  grid-template-rows: 1fr 10fr 1fr;
  grid-gap: 0px;
  max-height: 100vh;
  height: 100vh;
  color: rgba(0, 0, 0, 0.6);
  background-color: #f2eeee;
  background-image: url('../../assets/images/eye.svg');
  background-size: 120vw;
  background-repeat: no-repeat;
  background-position: 100% 50%;
  h1 {
    font-family: 'OpenDyslexicRegular';
  }
  button {
    text-transform: none;
    font-weight: 700;
  }
  .description {
    grid-area: d;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20%;
    color: #fff;
    h1 {
      margin-top: 0;
      line-height: 1.2;
      font-size: 64px;
      word-spacing: -25px;
    }
    p {
      font-size: 24px;
      font-family: Arial;
    }
  }

  header {
    grid-area: h;
    box-shadow: none;
  }
  .title {
    display: flex;
    flex-direction: column;
    justify-content: left;
    width: 60%;
    margin-bottom: 120px;
    position: relative;

    h1,
    p {
      margin: 0px;
    }
  }

  a {
    text-decoration: none;
  }
  .form {
    grid-area: f;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8vh;
    color: $grey;
    h1 {
      color: #000;
      font-size: 38px;
      letter-spacing: 3px;
    }
    .alertError {
      position: absolute;
      top: 100px;
      width: 90%;
      background-color: #eccdcd;
      border-radius: 8px;
      color: #191818;
      a {
        color: #191818;
        font-weight: bold;
      }
    }

    .error {
      color: red;
      margin-top: 5px;
    }
  }

  footer {
    grid-area: ff;
  }

  .modalContainer {
    background-color: $bg_black_color_opacity;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99;
  }
  .loginContainer {
    background-color: $bg_white_color;
    width: 440px;
    border-radius: 12px;
    padding-left: 32px;
    padding-right: 22px;
    padding-bottom: 35px;
    padding-top: 33px;
    position: relative;
  }
  .buttonClose {
    width: 12px;
    height: 24px;
    float: right;
  }
  .closeIcon {
    width: 24px;
    height: 24px;
  }
  .modalText {
    font-family: OpenSans, sans-serif;
    font-size: 36px;
    font-weight: 400;
    color: $black;
    margin-top: 20px;
  }
  .boldText {
    font-weight: 700;
  }
  .inputText {
    font-weight: 600;
    color: $black;
    font-size: 16px;
  }
  form {
    width: 100%;
    margin-bottom: 5vh;
    & > p {
      margin: 9px 0;
    }
    .submitButton {
      display: flex;
      width: 100%;
      margin-top: 50px;
      background-color: $purple;
      border-radius: 100px;
      height: 56px;
    }
    input {
      background-color: $white;
      border-radius: 12px;
      padding: 10.5px 14px;
      width: 100%;
    }
    button {
      background-color: $blue;
      padding: 8px 25px 8px 25px;
      color: $white;
    }
    .textField {
      display: flex;
      margin-bottom: 25px;
    }
    .passwordInfoContainer {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }
    .forgotPasswordLink {
      a {
        color: $black;
        font-weight: 600;
      }
    }
  }
}

@media only screen and (max-width: 1500px) {
  .wrapper {
    .description {
      margin-left: 18%;

      h1 {
        line-height: 1.1;
        font-size: 5vw;
      }
      p {
        font-size: 1.7vw;
      }
    }
  }
}
