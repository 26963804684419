.wrapper {
  display: grid;
  margin: 0 auto;
  grid-template-areas:
    '. . . '
    't m .'
    '. . .';
  grid-template-columns: 3fr 6fr 3fr;
  grid-template-rows: 1fr 10fr 1fr;
  grid-gap: 0px;
  height: 100vh;
  main {
    grid-area: m;
    border-radius: 8px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.12);
    padding: 25px 47px 25px 46px;
  }
  aside {
    grid-area: t;
  }
}
