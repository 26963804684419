@import '../../styles/colors.scss';
@import '../../styles/media.scss';

.footer {
  display: flex;
  position: fixed;
  bottom: 110px;
  right: 40px;
  box-shadow: 0px 5px 15px 0px #00000026;
  background-color: $bg_color_opacity;
  width: 358px;
  justify-content: center;
  padding: 18px;
  border-radius: 12px;
  .slider,
  .sliderAudio {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .pageInfo {
      font-size: 12px;
      font-weight: 600;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex: 1;
      margin: 0px;
      margin-top: 13px;
      p {
        margin: 0px;
        color: rgba(0, 0, 0, 0.25);
      }

      .pageNumber {
        font-size: 12px;
        font-weight: 600;
        margin: 0 10px;
        padding: 0 3px;
        width: 29px;
        height: 24px;
        border-radius: 8px;
        box-shadow: inset -1px 3px 6px 0 rgba(0, 0, 0, 0.12);
        border: solid 1px #bfbfbf;
        background-color: #ffffff;
        input {
          text-align: center;
        }

        input[type='number']::-webkit-inner-spin-button,
        input[type='number']::-webkit-outer-spin-button {
          -webkit-appearance: none !important;
          margin: 0;
        }
      }
    }
    .sliderWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 20px;
      .sliderBody {
        color: $blue;
        span.MuiSlider-rail {
          color: #fefefe;
        }
      }
      .prevNextButton {
        padding: 0px;
        background-color: #e7e7e7;
        border-radius: 8px;
      }
    }
  }
}

@media screen and (max-width: $break_point_md) {
  .footer {
    right: 18px;
  }
}

@media screen and (max-width: $break_point_sm) {
  .footer {
    width: calc(100vw - 32px);
    right: 50%;
    bottom: 14px;
    transform: translate(50%, 0%);
    box-sizing: border-box;
  }
}
