@import '../../styles/colors.scss';
@import '../../styles/media.scss';

:root {
  --fontFamily: 'OpenSansRegular';
  --chapterFlagColor: #cccccc;
  --fontSize: 16px;
  --fontColor: #191818;
  --fontWeight: normal;
  --wordSpacing: 1.15px;
  --paragraphSpacing: 24px;
  --lineHeight: 16px;
  --backgroundColor: $bg_color;
  --backgroundImage: none;
  --rulerTopHeight: 0;
  --rulerTopColor: #000000;
  --rulerTopOpacity: 0.25;
  --rulerBottomHeight: 0;
  --rulerBottomColor: #000000;
  --rulerBottomOpacity: 0.5;
  --rulerCursor: grab;
  --asideZindex: 3;
}

.bookPage {
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: var(--fontFamily);
  padding: 12px 30px 25px;
  z-index: 2;
  background-color: var(--backgroundColor);
  border-radius: 12px;
  box-shadow: var(--pageBoxShadow);
  .prevBlock {
    position: fixed;
    left: 21%;
    text-align: right;
    grid-area: l;
    z-index: 5;
  }
  .nextBlock {
    position: fixed;
    right: 21%;
    text-align: left;
    grid-area: r;
    z-index: 5;
  }
  .contentBlock {
    z-index: 1;
  }
  &:focus {
    outline: none;
  }
  &.ruler {
    .focusTopControls {
      .focusCloseButton {
        color: #ddd;
      }
    }
    // .focusArrowsControls {
    //   .prevNextButton {
    //     color: #ddd;
    //   }
    // }
  }
  &.ruler.rulerTop:before {
    pointer-events: all;
    cursor: var(--rulerCursor);
    position: absolute;
    width: 100vw;
    left: -25vw;
    height: calc(var(--rulerTopHeight));
    top: -8.3vh;
    content: '';
    background-color: var(--rulerTopColor);
    opacity: var(--rulerTopOpacity);
    z-index: 2;
  }
  &.ruler.rulerBottom:after {
    cursor: var(--rulerCursor);
    position: absolute;
    width: 100vw;
    top: calc(var(--rulerBottomHeight) + var(--lineHeight) / 5);
    bottom: -6.3vh;
    left: -25vw;
    content: '';
    background-color: var(--rulerBottomColor);
    opacity: var(--rulerBottomOpacity);
    z-index: 2;
  }
  .chapter {
    position: relative;
    font-size: 12px;
    line-height: 16px;
    opacity: 0.5;
    margin: 10px 0;
    letter-spacing: 0.4px;
    font-family: var(--fontFamily);
    padding: 0 22px;
    // height: 24px;
  }

  .chapterTitle {
    color: var(--fontColor);
    font-size: 18px;
    line-height: 24.5px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.06px;
    margin-bottom: 12px;
    height: 36px;
    font-family: var(--fontFamily);
    padding: 0 22px;
  }

  .image {
    max-width: 100%;
    max-height: 300px;
    height: auto;
    width: auto;
  }

  .imageCaption {
    font-weight: bold;
    text-align: center;
    margin-bottom: 15px;
  }

  div.paragraphTitle {
    font-family: var(--fontFamily);
    font-size: var(--fontSize);
    color: var(--fontColor);
    font-weight: var(--fontWeight);
    word-spacing: var(--wordSpacing);
    margin-bottom: 12px;
    margin-top: 34px;
    line-height: var(--lineHeight);
    background-image: var(--backgroundImage);
    position: relative;
    z-index: 5;
    word-break: break-word;
    p {
      font-family: var(--fontFamily);
      margin-bottom: 12px;
    }
  }

  div.paragraph {
    font-family: var(--fontFamily);
    font-size: var(--fontSize);
    color: var(--fontColor);
    margin-bottom: var(--paragraphSpacing);
    line-height: var(--lineHeight);
    background-image: var(--backgroundImage);
    font-weight: var(--fontWeight);
    word-spacing: var(--wordSpacing);
    position: relative;
    z-index: 5;
    word-break: break-word;
    p {
      font-family: var(--fontFamily);
      margin-bottom: 12px;
    }
  }
  .sound {
    user-select: none;
    &:hover,
    &.chosen {
      background-color: #a4cdff;
      cursor: pointer;
    }
  }
  .video {
    &:hover,
    &.chosen {
      background-color: #a4cdff;
      cursor: pointer;
    }
  }
  .audioVideoParagraph {
    user-select: none;
    &:hover,
    & .chosenAudio {
      background-color: #a4cdff;
      cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>🔈</text></svg>")
          16 0,
        auto;
    }
    &:hover,
    & .chosenVideo {
      background-color: #a4cdff;
      cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>📹</text></svg>")
          16 0,
        auto;
    }
  }
  .focusTopControls {
    position: absolute;
    display: flex;
    align-items: center;
    top: -40px;
    left: 0px;
    z-index: 5;
    .focusIcon {
      padding: 5px;
      margin-right: 11px;
      g > path {
        fill: #fff;
      }
    }
    .focusCloseButton {
      color: #c4c4c4;
      padding: 5px;
      margin-right: 14px;
    }
    .text {
      color: #fff;
      font-family: 'Open Sans', sans-serif;
      font-weight: 700;
      font-size: 14px;
    }
  }

  &.focusMode {
    margin: 80px 0;
  }
  .scrollButton {
    display: none;
    height: 48px;
    width: 48px;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    border: 1px solid $button_border;
    position: fixed;
    top: 20px;
    left: 16px;
    background-color: $white;
    z-index: 3;
    &:hover {
      background-color: $white;
    }
    &:active {
      background-color: $white;
    }
  }
  .homeMobileButton {
    padding: 0;
    display: none;
    height: 48px;
    width: 48px;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    border: 1px solid $button_border;
    position: fixed;
    top: 20px;
    left: 16px;
    background-color: $white;
    z-index: 3;
    &:hover {
      background-color: $white;
    }
    &:active {
      background-color: $white;
    }
  }
  .popper {
    margin: 0;
    .tooltip,
    .tooltipArrow {
      color: $white;
      background-color: $black;
      padding: 14px 12px;
      font-size: 16px;
      font-weight: bold;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
      border-radius: 8px;
    }
    .tooltip {
      margin-left: 31px;
    }
    .arrow {
      color: $black;
      font-size: 16px;
    }
  }
}

.videoFullPage {
  z-index: 3;
}

.chapterFlag {
  height: 40px;
  width: 10px;
  position: absolute;
  left: 0;
  top: 16px;
  .chapterArrowWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    .chapterArrowRight {
      position: absolute;
      background-color: var(--chapterFlagColor);
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      border-bottom-left-radius: 100px;
    }
    .chapterArrowLeft {
      position: absolute;
      background-color: var(--chapterFlagColor);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border-bottom-right-radius: 100px;
    }
  }
}

.focusModal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.videoModal {
  display: flex;
  align-items: center;
  justify-content: center;
  div:focus {
    outline: none;
  }
  .videoModalContent {
    position: relative;
    width: 640px;
    height: 360px;
    background-color: #000;
    border-radius: 8px;
    .videoPlayer {
      background-color: #000;
      outline: 5px solid transparent;
      box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.6);
      border-radius: 8px;
      text-align: center;
      &:focus {
        outline: none;
      }
    }
    .closeButton {
      color: #c2c2c1;
      position: absolute;
      right: 0px;
      top: 0px;
    }
  }
}

// @media screen and (max-width: $break_point_lg) {
//   .bookPage {
//     &.ruler.rulerTop:before {
//       height: calc(var(--rulerTopHeight) + 22px);
//     }
//   }
// }

// @media screen and (max-width: $break_point_md) {
//   .bookPage {
//     &.ruler.rulerTop:before {
//       height: calc(var(--rulerTopHeight) + 8px);
//     }
//   }
// }

@media screen and (max-width: $break_point_sm) {
  .bookPage {
    &.ruler.rulerTop:before {
      width: calc(100vw - 32px);
    }
    &.ruler.rulerBottom:after {
      width: calc(100vw - 32px);
    }
    margin-top: 0;
    margin-bottom: 0;
    height: auto;
    padding: 24px 16px 90px;
    border-radius: 0;
    .chapterFlag {
      display: none;
    }
    .contentBlock {
      .chapter {
        min-height: 60px;
        margin: 0 0 10px 0;
        padding: 0 0 0 60px;
        font-size: 20px;
        line-height: 21px;
      }
    }
    .chapterTitle {
      padding: 0;
      height: auto;
    }
    &.ruler {
      padding-right: 28px;
    }
    &.ruler.rulerTop:before {
      left: 0;
      height: calc(var(--rulerTopHeight) - 14px);
    }
    &.ruler.rulerBottom:after {
      left: 0;
    }
    .scrollButton {
      display: flex;
    }
    .homeMobileButton {
      display: flex;
    }
  }
  .videoFullPage {
    padding: 90px 16px 400px;
  }
}

@media screen and (max-width: $break_point_xs) {
  .bookPage {
    &.ruler.rulerTop:before {
      height: calc(var(--rulerTopHeight) - 22px);
    }
  }
}

@media screen and (max-width: $break_point_xxs) {
  .bookPage {
    &.ruler.rulerTop:before {
      height: calc(var(--rulerTopHeight) - 26px);
    }
  }
}

@media screen and (max-width: $break_point_xxxs) {
  .bookPage {
    &.ruler.rulerTop:before {
      height: calc(var(--rulerTopHeight) - 30px);
    }
  }
}

@media screen and (max-height: 860px) {
  .bookPage {
    &.ruler.rulerBottom:after {
      top: calc(var(--rulerBottomHeight) + var(--lineHeight) * 0.25 + 10px);
    }
  }
}

@media screen and (max-height: 740px) {
  .bookPage {
    &.ruler.rulerBottom:after {
      top: calc(var(--rulerBottomHeight) + var(--lineHeight) * 0.25 + 20px);
    }
  }
}

@media screen and (max-height: 620px) {
  .bookPage {
    &.ruler.rulerBottom:after {
      // top: calc(var(--rulerBottomHeight) + var(--lineHeight) * 0.25 + 30px);
    }
    &.ruler.rulerTop:before {
      height: calc(var(--rulerTopHeight) - 10px);
    }
  }
}

@media screen and (max-height: 520px) {
  .bookPage {
    &.ruler.rulerBottom:after {
      // top: calc(var(--rulerBottomHeight) + var(--lineHeight) * 0.25 + 35px);
    }
    &.ruler.rulerTop:before {
      height: calc(var(--rulerTopHeight) - 20px);
    }
  }
}
