@import '../../styles/colors.scss';
@import '../../styles/media.scss';

.pagination {
  position: fixed;
  display: flex;
  flex-direction: row;
  right: 40px;
  bottom: 27px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 5px 15px 0px #00000026;
  z-index: 2;
  .paginationArrowBack,
  .paginationArrowNext {
    height: 48px;
    width: 48px;
    border-radius: 12px;
    background-color: #ffffff;
    button {
      border-radius: 12px;
      width: 100%;
      height: 100%;
      min-width: auto;
      padding: 0;
      border: 1px solid $button_border;
    }
  }
  .paginationArrowNext {
    transform: rotate(180deg);
  }
  .paginationPages {
    padding: 7px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    .paginationCurrentPages {
      width: 41px;
      box-shadow: 0px 0px 5px 0px #00000040 inset;
      border-radius: 4px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      font-family: 'OpenSansMedium';
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.04px;
      background-color: #ffffff;
    }
    .paginationMaxPages {
      min-width: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'OpenSansSemiBold';
      font-weight: 700;
      font-size: 14px;
      letter-spacing: 0.04px;
    }
  }
}
.focusMode {
  background-color: $grey_opacity_background;
  .paginationPages {
    .paginationCurrentPages {
      box-shadow: none;
    }
  }
}

@media screen and (max-width: $break_point_md) {
  .pagination {
    right: 18px;
  }
}

@media screen and (max-width: $break_point_sm) {
  .pagination {
    right: 16px;
    bottom: 16px;
  }
  .mobileHidePagination {
    display: none;
  }
}
