@import '../../../../styles/colors.scss';
@import '../../../../styles/media.scss';

:root {
  --elTop: 0px;
}

.wrapper {
  display: flex;
  margin: 0;
  flex-direction: row;
  min-width: 360px;
  width: 100%;
  border-radius: 12px !important;
  box-sizing: border-box;
  .tabBody {
    width: calc(100% - 76px);
    margin: 0;
    box-sizing: border-box;
    padding: 0 16px;
    .tabBox {
      padding: 0px;
      box-sizing: border-box;
    }
  }
  .tabs {
    padding: 21px 16px;
    border-right: 0.5px solid $border_grey;
    width: 48px;
    > div {
      width: 48px;
      box-sizing: border-box;
    }
    button {
      height: 48px;
      width: 48px;
      min-width: 48px;
      opacity: 0.6;
      padding: 0;
      border-radius: 12px;
      margin-bottom: 24px;
      box-shadow: 0px 4px 20px 0px #0000000d;
      border: 1px solid $button_border;
      > span > svg {
        height: 24px;
        width: 24px;
      }
      &:hover {
        opacity: 1;
      }
      &.tabSelected {
        opacity: 1;
        background-color: $black;
        path {
          fill: $white;
        }
      }
    }
    .tabIndicator {
      display: none;
    }
  }
  .closeButton {
    color: #c2c2c1;
    position: absolute;
    right: 5px;
    top: 5px;
  }
  hr {
    border: none;
    height: 1px;
    background-color: #f1f1f0;
    margin: 15px 0;
  }
  .spacingOptionsTopSeparator {
    margin: 15px 0 24px;
  }
  .spacingOptionsBottomSeparator {
    margin: 10px 0 15px;
  }
  .backgroundTopSeparator {
    margin: 10px 0 6px;
  }
  .backgroundMiddleSeparator {
    margin: 20px 0 19px;
  }
  .backgroundBottomSeparator {
    margin: 11px 0 15px;
  }
  .sidebar {
    padding: 10px 6px;
    grid-area: s;
    display: flex;
    flex-direction: column;
    button {
      &:hover {
        path {
          fill: $blue;
        }
      }
    }
  }
  .main {
    grid-area: m;
    //padding: 20px;
    .title {
      margin-top: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      color: $black;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      font-family: 'OpenSansBold';
      button {
        width: 24px;
        height: 24px;
      }
    }
    .tabBodyHeader {
      display: flex;
      flex-direction: row;
      .closeButton {
        position: absolute;
        right: 12px;
        top: 18px;
        height: 24px;
        width: 24px;
      }
    }

    .sliderFonts {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      .sliderWrapper {
        width: calc(100% - 62px);
        box-sizing: border-box;
        display: flex;
        align-items: center;
        > span {
          display: flex;
          align-items: center;
          min-width: auto;
          margin: 0 10px;
        }
      }
      svg {
        height: 18px;
        width: 18px;
        max-width: auto;
      }
      svg {
        &:last-child {
          height: 24px;
          width: 24px;
          max-width: auto;
        }
      }
    }
    .fontWeight div {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: 100%;
      span {
        cursor: pointer;
        border-right: 1px solid #f1f1f0;
        padding: 0;
        padding-right: 30px;
        font-size: 28px;
        font-weight: 300;
        &:nth-child(2) {
          font-weight: 400;
        }
        &:last-child {
          border: none;
          padding-right: 0;
          font-weight: 600;
        }
      }
    }
    .colors {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      position: relative;
      .colorsTitle {
        font-family: 'OpenSansMedium';
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: $black;
      }
    }
  }
  .undoResetButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:first-child {
        width: 100px;
        padding: 9px 12px;
      }
      &:nth-child(2) {
        color: #ff0000;
      }
    }
    span {
      font-size: 1rem;
      text-transform: capitalize;
      font-weight: 600;
    }
  }
}
.popover {
  overflow: visible !important;
  left: 88px !important;
  top: auto !important;
  bottom: 120px !important;
  border-radius: 8px !important;
  width: 358px;
  height: 374px;
  box-sizing: border-box;
  background-color: $bg_white_color !important;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paper {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  width: 350px;
  text-align: center;
  &:focus {
    outline: none;
  }
  .actions {
    text-align: center;
    button {
      text-transform: none;
      font-weight: bold;
      border-radius: 8px;
      border: solid 2px #979797;
      padding: 5px 18px;
      margin: 0 8px;
      &.resetAll {
        color: #ff0000;
        border-color: #ff0000;
      }
      &.resetPartly {
        color: #fff;
        border: none;
        background-image: linear-gradient(105deg, #00abff 0%, #0073ff 100%);
        padding: 7px 18px;
      }
    }
  }
}

@media screen and (max-width: $break_point_sm) {
  .wrapper {
    min-width: auto;
    max-width: auto;
    hr {
      border: none;
      height: 1px;
      background-color: #f1f1f0;
      margin: 15px 0;
    }
    .backgroundMiddleSeparator {
      opacity: 0;
      margin: 17px 0 19px;
    }
    .spacingOptionsBottomSeparator {
      margin: 8px 0 14px;
    }
    .backgroundBottomSeparator {
      margin: 14.2px 0 15px;
    }
  }
  .popover {
    left: 16px !important;
    bottom: 16px !important;
  }
}
