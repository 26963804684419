@import '../../../../../styles/colors.scss';
@import '../../../../../styles/media.scss';

.wallpaperBlock {
  display: flex;
  .title {
    flex-grow: 4;
    font-size: 14px;
    font-weight: 600;
    padding: 7px 0;
    letter-spacing: 0.04px;
    font-family: 'OpenSansRegular';
  }
}

@media screen and (max-width: $break_point_sm) {
  .wallpaperBlock {
    opacity: 0;
    pointer-events: none;
  }
}
