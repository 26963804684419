@import '../../../../../styles/colors.scss';
@import '../../../../../styles/media.scss';

.undoResetButtons {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 0px;
    margin-bottom: 12px;
    cursor: pointer;
    height: 30px;
    &:first-child {
      border-radius: 0;
    }
    &:hover {
      background-color: transparent;
    }
  }
  .resetButton {
    span {
      color: $red;
    }
  }
  span {
    &:first-child {
      display: flex;
      font-size: 16px;
      line-height: 19.5px;
      text-transform: capitalize;
      // font-weight: 600;
      font-family: 'MontserratSemiBold';
      letter-spacing: 0.05px;
      position: relative;
      height: 100%;
      align-items: center;
      svg {
        position: absolute;
        left: 0;
      }
    }
  }
  hr {
    bottom: 0;
    position: absolute;
    border: none;
    width: 1px;
    background-color: #f1f1f0;
    margin: 0;
    height: 56px;
    right: 50%;
  }
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paper {
  background-color: #d9d9d9;
  opacity: 1;
  padding: 40px 40px 30px;
  border-radius: 8px;
  width: 340px;
  text-align: center;
  position: relative;
  .closeIcon {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
  }
  .titleWrapper {
    margin: 0px auto 30px;
    max-width: 300px;
    > h3 {
      font-family: 'OpenSansSemiBold';
      font-size: 18px;
      line-height: 20px;
      letter-spacing: 0.05px;
      margin: 0;
    }
  }

  &:focus {
    outline: none;
  }
  .actions {
    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: space-between;
    .buttonWrapperMargin {
      margin-right: 12px;
    }
  }
}
