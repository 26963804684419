@import '../../styles/colors.scss';
@import '../../styles/media.scss';

.main {
  grid-area: m;
  border-radius: 8px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 0px 10vw 25px 10vw;
  background-color: $bg_color;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 187px);
  box-sizing: border-box;
  .faq {
    font-size: 18px;
    padding: 20px 0px;
    .bold {
      font-weight: bold;
    }
    .red {
      color: red;
    }
    .underline {
      text-decoration: underline;
    }
    hr {
      margin-left: 55px;
    }
    .tabs {
      button {
        height: 56px;
        font-size: 18px;
        font-family: 'OpenSansBold';
        font-weight: 700;
        border-radius: 100px;
        text-transform: none;
        color: $black;
        margin-right: 30px;
        background-color: $white;
        border: 2px solid $black;
        &:last-child {
          margin-right: 0px;
        }
        &:hover {
          background-color: $purple;
          color: $white;
          border: 2px solid transparent;
        }
        &.tabSelected {
          background-color: $purple;
          color: $white;
          border: 2px solid transparent;
        }
      }
      .tabIndicator {
        display: none;
      }
    }
    .tabBox {
    }
    .accordionItem {
      background: none;
      box-shadow: none;
      &:before {
        height: 0;
      }
      .accordionSummary {
        flex-direction: row-reverse;
        padding: 0;
        .accordionHeading {
          margin-left: 30px;
          font-size: 24px;
          font-weight: bold;
        }
      }
      .accordionDetails {
        margin-left: 60px;
        font-size: 18px;
      }
    }
  }
  .toHome {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex: 1;
    .button {
      cursor: pointer;
      text-decoration: none;
      color: #000;
      display: inherit;
      flex-direction: row;
      align-items: center;
      svg {
        margin-right: 64px;
      }
      svg,
      h1 {
        font-size: 40px;
        font-family: GothamBold;
        line-height: 38px;
      }
    }
  }
}

.accordionExpandIcon {
  margin-right: 0 !important;
  padding: 12px 0 12px 0 !important;
}

.tabBox {
  padding: 50px 0px 0px 0px !important;
}

@media screen and (max-width: $break_point_md) {
  .main {
    padding: 25px 20px 25px 20px;
  }
}

@media screen and (max-width: $break_point_sm) {
  .main {
    padding: 95px 15px 0;
    box-shadow: none;
    .faq {
      font-size: 16px;
      padding: 10px 0px;
      .tabs {
        > div > div {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          max-width: calc(100vw - 32px);
          button {
            height: 43px;
            width: 25%;
            margin-right: 10px;
            font-size: 14px;
          }
        }
      }
      .tabBox {
        padding: 50px 10px !important;
      }
      .accordionItem {
        .accordionDetails {
          margin-left: 40px;
          font-size: 18px;
        }
      }
    }
  }
}
