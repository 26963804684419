@import '../../styles/colors.scss';
@import '../../styles/media.scss';

.main {
  display: flex;
  flex-direction: column;
  background-color: $bg_color;
  .top_container {
    display: flex;
    flex-direction: row;
    padding: 0px 58px 58px 0px;
    margin-top: 47px;
    height: 70vh;
    min-height: 700px;
    .left_top_block {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      border-right: 1px solid $black;
      padding-bottom: 58px;
      width: 155px;
      > div {
        font-size: 20px;
        color: $black;
        line-height: 27px;
        transform: rotate(270deg);
        font-family: 'OpenSansRegular';
      }
    }
    .right_top_block {
      position: relative;
      flex: 1;
      padding-left: 100px;
      .logo_text {
        display: flex;
        flex-direction: column;
        p {
          margin: 0;
          span {
            font-size: 160px;
            line-height: 160px;
            color: $black;
            margin-right: 15px;
          }
          .bold {
            font-family: 'OpenSansSemiBold';
            font-weight: 700;
          }
          .regular {
            font-family: 'OpenSansRegular';
            font-weight: 400;
          }
          .light {
            font-family: 'OpenSansLight';
            font-weight: 300;
          }
          .focusComponentWrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            .focusComponent {
              margin-left: 10px;
              margin-top: 25px;
              .focusMain {
                position: relative;
                background-color: $purple;
                border-radius: 12px;
                .focusArrow {
                  position: absolute;
                  height: 10px;
                  width: 10px;
                  transform: rotate(45deg);
                  background-color: $purple;
                  padding: 0;
                  left: -5px;
                  top: 27px;
                }
                .focusBody {
                  font-size: 24px;
                  line-height: 30px;
                  color: $white;
                  font-family: 'OpenSansSemiBold';
                  padding: 17px 30px;
                  font-weight: 700;
                }
              }
            }
          }
        }
      }
      .sub_logo_text {
        position: absolute;
        font-size: 18px;
        line-height: 30px;
        color: $black;
        right: 10%;
        bottom: 60px;
        max-width: 320px;
        font-family: 'OpenSansSemiBold';
        z-index: 1;
      }
      .eye_logo {
        position: absolute;
        bottom: 21%;
        left: 56%;
        > img {
          width: 300px;
          height: 229px;
        }
      }
    }
  }
  .bottom_container {
    background-color: $purple;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 800px;
    padding: 0;
    box-sizing: border-box;
    .video {
      position: absolute;
      z-index: 1;
      height: 800px !important;
      width: 100vw !important;
      video {
        object-fit: cover;
      }
    }
    .bottom_wrapper {
      position: relative;
      padding: 0 50px;
      z-index: 1;
      .bottom_body {
        position: relative;
        background-color: $white;
        box-shadow: 0px 5px 15px 0px #00000026;
        padding: 40px 60px;
        border-radius: 12px;
        z-index: 2;
        transition: transform 1s ease-out;
        &:hover {
          transform: scale(1.04);
        }
        .bottom_content {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          z-index: 1;
          .bottom_content_left {
            padding-right: 22px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            > h2 {
              margin: 0 0 2px 0;
              font-family: 'OpenSansRegular';
              color: $black;
              font-size: 36px;
              line-height: 49px;
              font-weight: 400;
              span {
                font-family: 'OpenSansBold';
              }
            }
            > p {
              width: 325px;
              margin: 0;
              font-family: 'OpenSansMedium';
              font-weight: 400;
              font-size: 18px;
              line-height: 30px;
            }
            > div {
              margin: 24px 0 0;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              padding: 0 20px;
              > p {
                width: 228px;
                margin: 0;
                font-family: 'OpenSansRegular';
                font-weight: 400;
                font-size: 18px;
                line-height: 30px;
              }
              > img {
                width: 34px;
                height: 30px;
              }
            }
          }
          .bottom_content_right {
            display: flex;
            justify-content: flex-end;
            .videoPlayer {
              border-radius: 12px;
            }
          }
        }
        .bottom_sign_up_button {
          padding-top: 37px;
          .boldText {
            font-family: OpenSansSemiBold;
            font-weight: 700;
            margin-right: 7px;
          }
        }
      }
    }
  }
}

.modalContainer {
  background-color: $bg_black_color_opacity;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding-top: 163px;
  z-index: 99;
}
.loginContainer {
  background-color: $bg_white_color;
  min-width: 380px;
  border-radius: 12px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 33px;
  position: relative;
}
.modalSignUpContainer {
  background-color: $bg_black_color_opacity;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-start;
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
}
.signUpContainer {
  top: 125px;
  right: 175px;
  background-color: $bg_white_color;
  min-width: 380px;
  border-radius: 12px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 33px;
  padding-bottom: 38px;
  position: relative;
}
.modalText {
  font-family: OpenSans, sans-serif;
  font-size: 36px;
  font-weight: 400;
  color: $black;
  margin-top: 20px;
}
.boldText {
  font-weight: 700;
}
.inputText {
  font-weight: 600;
  color: $black;
  font-size: 16px;
  margin-left: 9px;
}
.inputTextError {
  font-size: 12px;
  color: $red;
  margin-top: 8px;
  margin-left: 10px;
  font-weight: 600;
}
form {
  width: 100%;
  margin-bottom: 5vh;
  & > p {
    margin: 9px 0;
  }
  .inputFieldWrapper {
    border-width: 1px;
    border-style: solid;
    border-color: $border_inputs;
    border-radius: 12px;
    margin-bottom: 25px;
  }

  .inputFieldWrapperError {
    border-width: 1px;
    border-style: solid;
    border-color: $red;
    border-radius: 12px;
  }

  .inputFieldWrapperSuccess {
    border-width: 1px;
    border-style: solid;
    border-color: $green;
    border-radius: 12px;
    margin-bottom: 25px;
  }
  .submitButton {
    display: flex;
    width: 100%;
    margin-top: 25px;
    background-color: $purple;
    border-radius: 100px;
    height: 56px;
    color: $white;
    font-weight: 700;
    &:hover {
      background-color: $light_purple;
    }
  }
  input {
    background-color: $white;
    border-radius: 12px;
    padding: 10.5px 14px;
    width: 100%;
  }
  .textField {
    width: 100%;
  }
  .textFieldSignUp {
    width: 100%;
  }
  .passwordButton {
    height: 24px;
    width: 24px;
  }
  .passwordInfoContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .forgotPasswordButton {
    font-size: 16px;
    font-weight: 600;
    color: $black;
    border-radius: 0;
  }
  .spinnerSize {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 10px;
    top: 6px;
    bottom: 0;
    right: 0;
  }
}
.haveAccountText {
  font-family: OpenSansSemiBold;
  font-size: 18px;
  padding-left: 10px;
}

@media screen and (max-width: $break_point_xs) {
  .loginContainer {
    min-width: 300px;
  }

  .main {
    .top_container {
      .right_top_block {
        .logo_text {
          p {
            .focusComponentWrapper {
              .focusComponent {
                margin-top: 10px;
                .focusMain {
                  .focusArrow {
                    left: -5px;
                    top: 19px;
                  }
                  .focusBody {
                    font-size: 18px;
                    padding: 10px 15px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $break_point_xxs) {
  .loginContainer {
    min-width: 240px;
  }
}

@media only screen and (max-width: $break_point_xlg) {
  .main {
    .top_container {
      // .left_top_block {
      //   width: 120px;
      // }
      .right_top_block {
        padding-left: 50px;
        .logo_text {
          p {
            span {
              font-size: 140px;
              line-height: 130px;
            }
          }
        }

        .eye_logo {
          position: absolute;
          bottom: 25%;
          left: 66%;
          > img {
            width: 280px;
            height: 209px;
          }
        }
        .sub_logo_text {
          right: -1%;
          // bottom: -30px;
        }
      }
    }
  }
}

@media only screen and (max-width: $break_point_lg) {
  .main {
    .top_container {
      .left_top_block {
        width: 120px;
      }
      .right_top_block {
        padding-left: 50px;
        .logo_text {
          p {
            span {
              font-size: 120px;
              line-height: 130px;
            }
          }
        }
        .sub_logo_text {
          right: -1%;
          bottom: -30px;
        }
      }
    }
    .bottom_container {
      .bottom_wrapper {
        .bottom_body {
          padding: 20px 25px;
          .bottom_content {
            .bottom_content_left {
              > p {
                width: 305px;
              }
              > div {
                padding: 0;
                margin-top: 12px;
              }
            }
          }
          .bottom_sign_up_button {
            padding-top: 27px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $break_point_md) {
  .main {
    .top_container {
      .right_top_block {
        .logo_text {
          p {
            span {
              font-size: 120px;
              line-height: 110px;
            }
          }
        }
        .eye_logo {
          bottom: 0%;
          left: 0%;
        }
        .eye_logo {
          width: 220px;
          height: 159px;
          bottom: -5%;
          left: 5%;
          > img {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $break_point_sm) {
  .main {
    padding-top: 60px;
    .top_container {
      padding: 0;
      height: 70vh;
      min-height: 650px;
      .left_top_block {
        display: none;
      }
      .right_top_block {
        padding: 0px 17px;
        width: 100%;
        .logo_text {
          p {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            span {
              font-size: 70px;
              line-height: 90px;
            }
            .focusComponentWrapper {
              .focusComponent {
                .focusMain {
                  .focusBody {
                    padding: 10px 10px;
                    font-size: 18px;
                  }
                }
              }
            }
          }
        }
        .eye_logo {
          width: 232px;
          height: 173px;
          top: 350px;
          left: 27%;
          > img {
            height: 100%;
            width: 100%;
          }
        }
        .sub_logo_text {
          left: 16px;
          right: inherit;
          bottom: 25px;
        }
      }
    }
    .bottom_container {
      width: auto;
      padding: 40px 16px;
      box-sizing: border-box;
      .bottom_wrapper {
        padding: 0;
        box-sizing: border-box;
        width: 100%;
        .bottom_body {
          box-sizing: border-box;
          .bottom_content {
            flex-direction: column;
            background-color: #ffffffe5;
            .bottom_content_left {
              padding: 0;
              > p {
                width: 90%;
              }
              > div {
                align-items: flex-start;
                justify-content: flex-start;
                padding: 0 15 0 0px;
                > img {
                  margin-right: 30px;
                  margin-top: 7px;
                }
              }
            }
            .bottom_content_right {
              width: 100%;
              margin-top: 23px;
              box-sizing: border-box;
              > div {
                width: 100%;
                iframe {
                  width: 100%;
                }
              }
            }
          }
          .bottom_sign_up_button {
            margin-top: 0px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $break_point_xxxs) {
  .main {
    .top_container {
      min-height: 750px;
      .right_top_block {
        width: 100%;
        .logo_text {
          p {
            flex-wrap: wrap;
            span {
              margin-right: 5px;
            }
          }
        }
        .eye_logo {
          width: 222px;
          height: 163px;
          top: 450px;
          left: 25%;
          > img {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
    .bottom_container {
      .bottom_wrapper {
        .bottom_body {
          padding: 10px;
          .bottom_content {
            .bottom_content_left {
              h2 {
                font-size: 18px;
              }
              p {
                font-size: 16px;
              }
              > div {
                img {
                  margin-right: 10px;
                }
                p {
                  font-size: 14px;
                  width: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}
