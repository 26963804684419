@import '../../styles/colors.scss';
@import '../../styles/media.scss';

.footer {
  z-index: 2;
  padding: 15px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  background-color: $bg_color;
  color: #000;
  border-top: 1px solid $border_grey;
  .left_group {
    display: flex;
    flex-direction: row;
    width: 35%;
    justify-content: space-between;
    align-items: center;
    .links {
      display: flex;
      flex-direction: row;
      width: 50%;
      justify-content: space-between;
      a {
        text-decoration: none;
        color: $black;
        font-size: 16px;
      }
    }
    > div {
      p {
        color: $black;
        font-size: 16px;
      }
    }
  }
  .right_group {
    display: flex;
    flex-direction: row;
    width: 24%;
    justify-content: space-between;
    align-items: center;
    > div {
      display: flex;
      align-items: center;
      color: $black;
      font-size: 16px;
      > img {
        margin: 0 8px 0 5px;
      }
    }
  }
  .commercialBreakMobile {
    display: none;
  }
}

.cookiePopover {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 660px;
  padding: 35px;
  margin-left: -30px;
  margin-top: -10px;
  border-radius: 8px !important;
  .cookieIcon {
    width: 15%;
    svg {
      width: 70px;
      height: 70px;
    }
  }
  .cookieText {
    width: 50%;
  }
  .cookieAccept {
    width: 35%;
    text-align: end;
    button {
      width: 110px;
      height: 50px;
      background-color: #0073ff;
      text-transform: none;
      font-size: 16px;
    }
  }
}

@media screen and (max-width: $break_point_xxlg) {
  .footer {
    .left_group {
      width: 45%;
    }
    .right_group {
      width: 35%;
    }
  }
}

@media screen and (max-width: $break_point_lg) {
  .footer {
    .left_group {
      width: 55%;
    }
    .right_group {
      > div {
        margin-bottom: 5px;
      }
    }
  }
}

@media screen and (max-width: $break_point_md) {
  .footer {
    .left_group {
      width: 55%;
      .links {
        width: 40%;
        flex-direction: column;
        a {
          font-size: 14px;
        }
        > div {
          p {
            font-size: 14px;
          }
        }
      }
    }
    .right_group {
      flex-direction: column;
      align-items: flex-end;
      div {
        font-size: 14px;
        &:last-child {
          margin-top: 0px;
        }
      }
    }
  }
}

@media screen and (max-width: $break_point_sm) {
  .footer {
    flex-direction: column;
    border: none;
    padding: 16px;
    outline: none;
    .left_group {
      width: 100%;
      .links {
        flex-direction: row;
        width: 100%;
        padding-right: 20px;
      }
      .commercialBreak {
        display: none;
      }
    }
    .right_group {
      width: 100%;
      flex-direction: row;
      margin: 16px 0;
    }
    .commercialBreakMobile {
      display: flex;
      width: 100%;
      font-size: 14px;
      p {
        margin: 0;
        color: $black;
      }
      margin-bottom: 15px;
    }
  }
  .whiteBackground {
    background-color: $white;
  }
}
