@import '../../../../../styles/colors.scss';
@import '../../../../../styles/media.scss';

:root {
  --elTop: 0px;
}

hr {
  border: none;
  height: 1px;
  background-color: #f1f1f0;
  margin: 15px 0;
}
.wrapper {
  display: flex;
  margin: 0;
  max-width: 17vw;
  min-width: 358px;
  border-radius: 12px !important;
  flex-direction: row;
  justify-content: space-between;

  .closeButton {
    position: absolute;
    right: 12px;
    top: 18px;
    height: 24px;
    width: 24px;
    color: #c2c2c1;
  }

  .sidebar {
    padding: 21px 16px;
    display: flex;
    flex-direction: column;
    width: 80px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-right: 0.5px solid $border_grey;
    box-sizing: border-box;
    margin-bottom: 60px;
    .sidebarIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 0.5px solid $border_grey;
      width: 48px;
      height: 48px;
      border-radius: 12px;
      background-color: $black;
      box-shadow: 0px 4px 20px 0px #0000000d;
      > svg > g > path {
        fill: $white;
      }
    }
  }
  .main {
    width: calc(100% - 80px);
    padding: 0 16px;
    .title {
      margin-top: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      color: $black;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      font-family: 'OpenSansBold';
      button {
        width: 24px;
        height: 24px;
      }
    }
    .closeButton {
      position: absolute;
      right: 5px;
      top: 5px;
    }
  }
}
.popover {
  overflow: visible !important;
  left: 88px !important;
  top: auto !important;
  bottom: 120px !important;
  border-radius: 8px !important;
  width: 360px;
  box-sizing: border-box;
  background-color: $bg_white_color !important;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paper {
  padding: 15px;
  border-radius: 8px;
  width: 350px;
  text-align: center;
  &:focus {
    outline: none;
  }
  .actions {
    text-align: center;
    button {
      text-transform: none;
      font-weight: bold;
      border-radius: 8px;
      border: solid 2px #979797;
      padding: 5px 18px;
      margin: 0 8px;
      &.resetAll {
        color: #ff0000;
        border-color: #ff0000;
      }
      &.resetPartly {
        color: #fff;
        border: none;
        background-image: linear-gradient(105deg, #00abff 0%, #0073ff 100%);
        padding: 7px 18px;
      }
    }
  }
}

@media screen and (max-width: $break_point_sm) {
  .popover {
    left: 16px !important;
    bottom: 16px !important;
  }
  .wrapper {
    // margin: 0 16px;
    min-width: 100%;
    // width: calc(100vw - 16px);
  }
}
