@import '../../../styles/media.scss';

:root {
  --fontColor: #000;
}

.colorPreview {
  width: 22px;
  height: 22px;
  border-radius: 8px;
  background-color: var(--fontColor);
  position: relative;
}
.colorPicker {
  position: absolute;
  top: -20px;
  right: -95%;
  z-index: 999;
}

@media screen and (max-width: $break_point_sm) {
  .colorPicker {
    top: -140px;
    right: 30px;
  }
}
