@import '../../styles/colors.scss';
@import '../../styles/media.scss';

.book {
  position: relative;
  margin: 0 2.5% 30px 0;
  width: 31%;
  height: 230px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: 16px;
  border-radius: 16px;
  text-decoration: none;
  > img {
    height: 200px;
    min-width: 200px;
    object-fit: fill;
  }
  .bookInfoWrapper {
    display: flex;
    flex-direction: column;
    padding: 24px 0 24px 15px;
    width: calc(100% - 188px);
    justify-content: space-between;
    padding-bottom: 20px;
    .bookInfo {
      h3 {
        margin: 0;
        font-family: 'OpenSansRegular';
        font-weight: 400;
        color: $black;
        opacity: 0.5;
        font-size: 16px;
        line-height: 20px;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        width: 100%;
      }
      p {
        margin: 3px 0 0 0;
        font-family: 'OpenSansBold';
        color: $black;
        font-size: 17px;
        // line-height: 27px;
        text-overflow: ellipsis;
      }
    }
    .progressBar {
      width: 60%;
      height: 8px;
      border-radius: 8px;
      background-color: $progress_grey;
      & > div {
        background-color: $purple;
      }
    }
  }
  .hoverReadButton {
    position: absolute;
    top: 130px;
    left: 110px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: $bg_color;
    padding: 7px;
    border-radius: 16px;
    display: none;
    text-decoration: none;
    .playIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px;
      background-color: $purple;
      border-radius: 12px;
    }
    p {
      font-size: 20px;
      line-height: 27px;
      font-family: OpenSansBold;
      color: $black;
      padding: 0 15px;
      margin: 0;
    }
  }
  .hoverPageProgress {
    display: none;
    position: absolute;
    top: 23px;
    left: 67px;
    padding: 12px 0px;
    background-color: $purple;
    border-radius: 100px;
    font-size: 16px;
    line-height: 20px;
    font-family: OpenSansBold;
    color: $white;
    min-width: 95px;
    box-sizing: border-box;
    justify-content: center;
  }

  &:hover {
    background-color: $black;
    box-shadow: 0px 5px 15px 0px $hover_black;
    cursor: none;
    .bookInfoWrapper {
      .bookInfo {
        h3 {
          color: $white;
          opacity: 0.5;
        }
        p {
          color: $white;
        }
      }
    }
    .hoverReadButton {
      position: fixed;
      top: 0;
      left: 0;
      transform: translate(-50%, -50%);
      will-change: width, height, transform, border;
      z-index: 999;
      pointer-events: none;
      display: flex;
    }
    .hoverPageProgress {
      display: flex;
    }
  }
}
.last_book {
  margin: 0;
}

@media screen and (max-width: $break_point_xxlg) {
  .book {
    width: 31%;
  }
  .last_book {
    margin: 0 2% 50px 0;
  }
}

@media screen and (max-width: $break_point_lg) {
  .book {
    width: 45%;
    &:hover {
      .hoverReadButton {
        display: none;
      }
      .hoverPageProgress {
        display: none;
      }
    }
  }
  .last_book {
    margin: 0 2% 30px 0;
  }
}

@media screen and (max-width: $break_point_md) {
  .book {
    width: 80%;
  }
}

@media screen and (max-width: $break_point_xs) {
  .book {
    width: 50%;
    display: flex;
    flex-direction: column;
    margin: 0;
    height: auto;
    padding: 16px 8px 16px 16px;
    align-items: center;
    > img {
      height: 180px;
      width: 100%;
      min-width: 100%;
      object-fit: fill;
      box-shadow: 0px 5px 15px 0px #00000026;
    }
    .bookInfoWrapper {
      width: 100%;
      padding: 16px 0 0;
      .bookInfo {
        p {
          margin: 0 0;
          font-size: 18px;
        }
        h3 {
          font-size: 16px;
        }
      }
      .progressBar {
        display: none;
      }
    }
    .hoverPageProgress {
      display: flex;
      top: 145px;
      left: 50%;
      transform: translate(-50%, 50%);
    }
    &:hover {
      background-color: transparent;
      box-shadow: none;
      .bookInfoWrapper {
        .bookInfo {
          h3 {
            color: $black;
            opacity: 0.5;
          }
          p {
            color: $black;
          }
        }
      }
      .hoverPageProgress {
        display: flex;
      }
    }
  }

  .mobile_last_book {
    padding: 16px 16px 16px 8px;
    .hoverPageProgress {
      left: 50%;
    }
  }
}

@media screen and (max-width: $break_point_xxxs) {
  .book {
    width: 50%;
    display: flex;
    flex-direction: column;
    margin: 0;
    height: auto;
    padding: 16px 8px 16px 16px;
    align-items: center;
    > img {
      height: 150px;
      width: 100%;
      min-width: 100%;
      object-fit: fill;
      box-shadow: 0px 5px 15px 0px #00000026;
    }
    .hoverPageProgress {
      display: flex;
      top: 120px;
      left: 53%;
      transform: translate(-50%, 50%);
    }
  }
}
