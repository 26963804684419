@import '../../styles/colors.scss';
@import '../../styles/media.scss';

.registerBlock {
  padding: 25px 33px 5px 33px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 12px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
  max-width: 400px;
  margin-bottom: 25px;

  .heading {
    margin: 0;
    font-size: 36px;
  }

  .boldText {
    font-family: OpenSansBold;
  }

  form {
    .upperLabel {
      font-family: OpenSansSemiBold;
      font-size: 16px;
      color: $black;
      padding-left: 8px;
    }
    .passwordWrapper {
      display: flex;
      flex: 1;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
    }
    .passwordColumn {
      display: flex;
      flex-direction: column;
    }
    input {
      background-color: $white;
      border-radius: 12px;
      padding: 20px 14px;
      width: 100%;
      font-family: OpenSansSemiBold;
      font-size: 16px;
    }
    .textField {
      width: 100%;
    }
    .inputFieldWrapper {
      border-width: 1px;
      border-style: solid;
      border-color: $border_inputs;
      border-radius: 12px;
    }

    .inputFieldWrapperError {
      border-width: 1px;
      border-style: solid;
      border-color: $red;
      border-radius: 12px;
    }

    .inputFieldWrapperSuccess {
      border-width: 1px;
      border-style: solid;
      border-color: $green;
      border-radius: 12px;
    }
    .inputTextError {
      font-size: 12px;
      color: $red;
      margin-top: 8px;
      margin-left: 10px;
      font-weight: 600;
    }
    .textFieldPassword {
      width: 190px;
    }
    .arrowContainer {
      background-color: red;
      display: flex;
      flex: 1;
      justify-content: center;
      align-content: center;
    }
    .blockWithButton {
      background-color: $white;
      border-radius: 12px;
    }
    .submitButton {
      display: flex;
      justify-content: center;
      margin-top: 25px;
      width: 100%;
      border-radius: 100px;
      font-size: 18px;
      line-height: 20px;
      height: 56px;
      background-color: $purple;
      &:hover {
        background-color: $light_purple;
      }
      button {
        color: $white;
        text-transform: none;
        font-weight: 700;
        width: 100%;
        font-size: 18px;
      }
    }
  }

  .passwordButton {
    height: 24px;
    width: 24px;
  }

  .selectField {
    display: flex;
    width: 100%;
    background-color: $white;
    border-radius: 12px;
    font-family: OpenSansSemiBold;
  }
  .test {
    background-color: red;
  }

  .selectError {
    margin-left: 14px;
    margin-right: 14px;
  }

  .emailUpdates {
    align-items: flex-start;
    color: $checkbox_color;
    .label {
      padding-top: 11px;
      font-size: 200px;
    }
  }

  .termsUpdates {
    color: $checkbox_color;
  }

  .checkBoxWrapper {
    margin-left: -11px;
    cursor: pointer;
    display: inline-flex;
    align-items: flex-start;
    margin-right: 16px;
    vertical-align: middle;
    .label {
      font-size: 16px;
      padding-top: 10px;
    }
    a {
      text-decoration-color: $purple;
    }
    .link {
      font-family: 'OpenSansBold';
      color: $purple;
    }
  }

  .checkboxChecked {
    svg {
      fill: $checkbox_color;
    }
  }
}

@media screen and (max-width: $break_point_sm) {
  .registerBlock {
    padding: 88px 15px 28px;
    box-shadow: none;
    background-color: $bg_color;
    margin-bottom: 0px;
    form {
      .textFieldPassword {
        width: 100%;
      }
      .passwordWrapper {
        flex-direction: column;
        > div > div {
          width: 100%;
        }
      }
    }
  }
}
