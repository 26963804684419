@import '../../styles/colors.scss';
@import '../../styles/media.scss';

.mainWrapper {
  display: flex;
  // flex: 1;
  justify-content: center;
  align-items: center;
  background-image: url('../../assets/images/reset-bg-eye.svg');
  background-size: 35%;
  background-repeat: no-repeat;
  background-position: center right;
}

.container {
  width: 440px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
  background-color: $bg_white_color;
  border-radius: 12px;
  padding-left: 32px;
  padding-right: 32px;
  margin-bottom: 20px;
  // height: 580px;
}

.mainText {
  margin: 0;
  font-family: OpenSans, sans-serif;
  font-size: 36px;
  font-weight: 400;
  color: $black;
  margin-top: 20px;
}

.boldText {
  font-weight: 700;
}

.inputText {
  font-weight: 600;
  color: $black;
  font-size: 16px;
  margin-left: 9px;
}

.inputTextError {
  font-size: 12px;
  color: $red;
  margin-top: 8px;
  margin-left: 10px;
  margin-bottom: 0;
  font-weight: 600;
}

.inputFieldWrapper {
  border-width: 1px;
  border-style: solid;
  border-color: $border_inputs;
  border-radius: 12px;
}

.inputFieldWrapperError {
  border-width: 1px;
  border-style: solid;
  border-color: $red;
  border-radius: 12px;
}

.inputFieldWrapperSuccess {
  border-width: 1px;
  border-style: solid;
  border-color: $green;
  border-radius: 12px;
}

.textField {
  width: 100%;
  margin-bottom: 25px;
}

.submitButton {
  display: flex;
  width: 100%;
  background-color: $purple;
  margin-top: 25px;
  height: 56px;
  border-radius: 100px;
  &:hover {
    background-color: $light_purple;
  }
  button {
    width: 100%;
    color: $white;
    text-transform: none;
    font-weight: 700;
    width: 100%;
    font-size: 18px;
  }
}

.passwordButton {
  height: 24px;
  width: 24px;
}

@media screen and (max-width: $break_point_xs) {
  .container {
    padding-left: 16px;
    padding-right: 16px;
  }
}
