@import '../../../styles/colors.scss';
@import '../../../styles/media.scss';

.slider {
  width: 75%;
}
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 70%;
  }
  .waveform {
    width: 100%;
    height: 16px;
    span {
      color: $black_with_opacity_ten;
      z-index: 2;
      padding: 0;
      &:nth-child(1) {
        height: 8px;
        border-radius: 8px;
        opacity: 1;
        z-index: 1;
      }
      &:nth-child(2) {
        color: $purple !important;
        background-color: $purple !important;
        opacity: 1;
        height: 8px;
        border-radius: 8px;
        margin-left: 0;
      }
      &:nth-child(4) {
        height: 3px;
        width: 2 !important;
        color: $purple;
        background-color: $purple;
        opacity: 0;
        border: none;
        margin: 0;
      }
    }
    & > div {
      height: 0px;
    }
  }

  .iframe {
    height: 0px !important;
  }
  div:nth-child(3) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    button {
      min-width: 30px;
      padding: 2px;
    }
  }
  button.playButton {
    min-width: 48px;
    max-width: 48px;
    height: 48px;
    text-align: center;
    align-items: center;
    background-color: $black;
    border-radius: 12px;
    > span {
      height: 24px;
      width: 24px;
      > span {
        margin: 0;
      }
    }
    &:disabled {
      background-color: $grey_text;
    }
  }
  .activeButton {
    svg {
      path {
        fill: #0073ff;
      }
    }
  }
  .skipButton {
    cursor: pointer;
    padding: 0;
    width: 24px;
    height: 24px;
    min-width: auto;
    min-height: auto;
    &:disabled {
      > span > svg > path {
        fill: $grey_text;
      }
    }
  }
  .rewindButton {
    cursor: pointer;
    padding: 0;
    width: 24px;
    height: 24px;
    min-width: auto;
    min-height: auto;
    &:disabled {
      > span > svg > path {
        fill: $grey_text;
      }
    }
  }
  .closeButton {
    cursor: pointer;
    padding: 0;
    width: 24px;
    height: 24px;
    min-width: auto;
    min-height: auto;
    border-radius: 50%;
  }
}
