@import '../../../../../styles/colors.scss';
@import '../../../../../styles/media.scss';

.colorPicker {
  .sketchPicker {
    background-color: $bg_white_color !important;
    width: 100% !important;
    padding: 0 !important;
    box-sizing: initial !important;
    box-shadow: none !important;
    > div:last-child {
      border-top: none !important;
      padding-top: 5px !important;
      justify-content: space-between;
    }
    > div:first-child {
      // height: 68px;
      padding-bottom: 68px !important;
    }
  }
}
