@import '../../../styles/colors.scss';
@import '../../../styles/media.scss';

.slider {
  margin: 0;
  height: 11px !important;
  display: flex;
  align-items: center;
  padding: 16px 0 10px !important;
}

.track {
  height: 4px !important;
  border-radius: 12px !important;
  background-image: $purple;
}

.rail {
  height: 4px !important;
  border-radius: 12px !important;
  color: #ededed;
}

.thumb {
  background-color: $purple !important;
  border: solid 2px #fefefe !important;
  height: 24px !important;
  width: 24px !important;
  margin-top: -11px !important;
  box-shadow: 0px 0px 6px 0px #1f29370d;

  &:hover {
    box-shadow: 0px 0px 6px 0px #1f29370d !important;
  }
  &:active {
    box-shadow: 0px 0px 6px 0px #1f29370d !important;
  }
}

@media screen and (max-width: $break_point_sm) {
  .slider {
    padding: 13.5px 0 !important;
  }
}
