.switchWrapper {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > span {
    box-shadow: 0px 0px 6px 0px #1f29370d;
    > span:first-child {
      box-shadow: 0px 0px 6px 0px #1f29370d;
    }
  }
}
