@import '../../styles/colors.scss';
@import '../../styles/media.scss';

.main {
  grid-area: m;
  border-radius: 8px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 25px 64px;
  background-color: $bg_color;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  min-height: calc(100vh - 194px);
  .chaptersWrapper {
    width: 100%;
    padding: 0 86px;
    box-sizing: border-box;
    .chapter {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      background-color: $white_opacity_background;
      border-radius: 8px;
      margin: 12px 0 12px 0;
      padding: 15px 25px 15px 30px;
      position: relative;
      text-decoration: none;
      box-shadow: 0 5px 10px 0 #0000001a;
      min-height: 91px;
      box-sizing: border-box;
      .chapterHeader {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: 100%;
        h3 {
          font-size: 24px;
          line-height: 32px;
          margin: 0 0 9px 0;
          color: $black;
        }
        .chapterAppendix {
          color: $black;
          font-size: 20px;
          line-height: 20px;
          opacity: 0.5;
          min-height: 20px;
        }
      }
      .buttons {
        > div {
          background: #fff;
          border-radius: 12px;
          margin: 0px 10px 0px 0px;
          width: 48px;
          height: 48px;
          padding: 0;
          border: 1px solid $button_border;
          display: flex;
          justify-content: center;
          align-items: center;
          .activeVideoIcon {
            display: none;
          }
        }
      }
      &:hover {
        background-color: $black;

        .chapterHeader {
          h3,
          .chapterAppendix {
            color: #fff;
          }
        }
      }
      .bookmark {
        position: absolute;
        top: 0;
        left: 0;
        min-height: 45px;
        min-width: 14px;
        border-bottom-right-radius: 16px;
        border-top-left-radius: 8px;
      }
      a {
        text-decoration: none;
        color: #000;
      }
    }
    .chapterActive {
      background-color: $purple;
      .chapterHeader {
        h3,
        .chapterAppendix {
          color: $white;
        }
      }
      .buttons {
        > div {
          background-color: $black;
          .activeVideoIcon {
            display: block;
          }
          .videoIcon {
            display: none;
          }
        }
      }
      &:hover {
        .buttons {
          > div {
            background-color: $white;
            .activeVideoIcon {
              display: none;
            }
            .videoIcon {
              display: block;
            }
          }
        }
      }
    }
  }
  .toHome {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    .button {
      cursor: pointer;
      text-decoration: none;
      color: #000;
      display: inherit;
      flex-direction: row;
      align-items: center;
      button {
        margin-right: 43px;
        height: 48px;
        width: 48px;
        border: 1px solid $button_border;
        background-color: $white;
        border-radius: 12px;
        font-size: 16px;
        box-sizing: border-box;
        min-width: 48px;
        > span {
          padding-right: 5px;
        }
      }
      h1 {
        font-size: 40px;
        line-height: 38px;
        font-family: 'GothamBold';
      }
    }
  }
}
footer {
  grid-area: f;
}

.videoModal {
  display: flex;
  align-items: center;
  justify-content: center;
  div:focus {
    outline: none;
  }
  .videoModalContent {
    position: relative;
    .videoPlayer {
      background-color: #000;
      outline: 5px solid transparent;
      box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.6);
      border-radius: 8px;
      text-align: center;
      &:focus {
        outline: none;
      }
    }
    .closeButton {
      color: #c2c2c1;
      position: absolute;
      right: 0px;
      top: 0px;
    }
  }
}

@media screen and (max-width: $break_point_sm) {
  .main {
    padding: 80px 16px 40px;
    .toHome {
      margin-bottom: 26px;
      .button {
        button {
          margin-right: 25px;
        }
        h1 {
          font-size: 24px;
          line-height: 32px;
          margin: 0;
        }
      }
    }
    .chaptersWrapper {
      padding: 0px;
      box-sizing: border-box;
      .chapter {
        flex-direction: column;
        width: 100%;
        box-sizing: border-box;
        margin: 0 0 12px;
        padding: 18px 16px 16px;
        min-height: 200px;
        .chapterHeader {
          width: 100%;
          h3 {
          }
          .chapterAppendix {
          }
        }
        .buttons {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          > div {
            margin: 0;
          }
        }
      }
    }
  }
}
