@import '../../../styles/colors.scss';
@import '../../../styles/media.scss';

:root {
  --elTop: 0px;
}

.main {
  display: flex;
  flex: 1;
  margin: 0;
  flex-direction: column;
  .timerPredefineButtons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    padding-top: 14px;
    padding-bottom: 14px;
    border-top-style: solid;
    border-top-width: 0.5px;
    border-top-color: $hr_border_color;
    border-bottom-style: solid;
    border-bottom-width: 0.5px;
    border-bottom-color: $hr_border_color;
    button {
      text-transform: none;
      border-radius: 100px;
      padding: 5px 14px;
      color: #fff;
      font-family: OpenSansBold;
      font-size: 14px;
      background-color: $purple;
    }
  }
  .manualTimer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    svg {
      height: 20px;
      width: 20px;
      margin: 3px 0;
    }
    .focusTimerInput {
      font-size: 14px;
      font-weight: 600;
      margin-right: 5px;
      padding: 0 3px;
      width: 54px;
      height: 30px;
      border-radius: 8px;
      box-shadow: inset -1px 3px 6px 0 rgba(0, 0, 0, 0.12);
      border: solid 1px #bfbfbf;
      background-color: #ffffff;
      input {
        text-align: center;
        padding: 8px 0 7px;
      }

      input[type='number']::-webkit-inner-spin-button,
      input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none !important;
        margin: 0;
      }
    }
    .minText {
      font-family: OpenSansSemiBold;
      font-size: 14px;
    }
  }
  .manualTimerActivateButton {
    width: 100%;
    text-transform: none;
    border-radius: 100px;
    padding: 6px 14px;
    color: $white;
    font-weight: 700;
    font-size: 14px;
    border: none;
    background-color: $purple;
    &:hover {
      background-color: $light_purple;
    }
  }

  .separator {
    width: 100%;
    height: 0.5px;
    background-color: $hr_border_color;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
  }

  .endlessActivateButton {
    width: 100%;
    text-transform: none;
    border-radius: 100px;
    padding: 5px 14px;
    color: $black;
    font-weight: 700;
    box-shadow: none;
    background-color: $white;
    border: 1px solid $black;
    font-size: 14px;
    &:hover {
      background-color: rgba(45, 54, 59, 0.1);
    }
  }
  .focusPopupFooterPopOver {
    padding-bottom: 20px;
  }
}

.popover {
  overflow: visible !important;
  left: 24vw !important;
  border-radius: 8px !important;
  width: 336px;
}

.paper {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  width: 350px;
  text-align: center;
  &:focus {
    outline: none;
  }
  .actions {
    text-align: center;
    button {
      text-transform: none;
      font-weight: bold;
      border-radius: 8px;
      border: solid 2px #979797;
      padding: 5px 18px;
      margin: 0 8px;
      &.resetAll {
        color: #ff0000;
        border-color: #ff0000;
      }
      &.resetPartly {
        color: #fff;
        border: none;
        background-image: linear-gradient(105deg, #00abff 0%, #0073ff 100%);
        padding: 7px 18px;
      }
    }
  }
}

.focusPaper {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 12px;
  padding-top: 22px;
  padding-bottom: 22px;
  width: 295px;
  display: flex;
  flex-direction: column;
  h3 {
    font-family: OpenSansBold;
    font-size: 18px;
    line-height: 20px;
    margin: 0;
  }
  p {
    font-family: OpenSansBold;
    font-size: 18px;
    line-height: 20px;
    margin-top: 20px;
  }
  &:focus {
    outline: none;
  }
  .actions {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .buttonsWrapper {
      display: flex;
      width: 100%;
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-top: 14px;
      padding-bottom: 14px;
      border-top-style: solid;
      border-top-width: 0.5px;
      border-top-color: $hr_border_color;
      border-bottom-style: solid;
      border-bottom-width: 0.5px;
      border-bottom-color: $hr_border_color;
    }
    .focusManualTimerBlockWrapper {
      display: flex;
      justify-content: space-between;
      align-content: center;
      padding-top: 15px;
      padding-bottom: 15px;
    }
    button {
      text-transform: none;
      border-radius: 100px;
      padding: 5px 14px;
      color: $white;
      font-family: OpenSansBold;
      font-size: 14px;
      background-color: $purple;
    }
    .focusManualTimerBlock {
      .focusTimerInput {
        font-size: 14px;
        font-weight: 600;
        margin-right: 5px;
        padding: 0 3px;
        width: 54px;
        height: 30px;
        border-radius: 8px;
        box-shadow: inset -1px 3px 6px 0 rgba(0, 0, 0, 0.12);
        border: solid 1px #bfbfbf;
        background-color: #ffffff;
        input {
          text-align: center;
          padding: 8px 0 7px;
        }

        input[type='number']::-webkit-inner-spin-button,
        input[type='number']::-webkit-outer-spin-button {
          -webkit-appearance: none !important;
          margin: 0;
        }
      }
      .minText {
        font-family: OpenSansSemiBold;
        font-size: 14px;
      }
    }
  }
  .focusPopupFooter {
    display: flex;
    flex-direction: column;
  }

  .activateFocusButton {
    text-transform: none;
    border-radius: 100px;
    padding: 10px 0px;
    color: $white;
    font-family: OpenSansBold;
    font-size: 14px;
    background-color: $purple;
    &:hover {
      background-color: $light_purple;
    }
  }

  .separator {
    width: 100%;
    height: 0.5px;
    background-color: $hr_border_color;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
  }

  .exitFocusButton {
    color: $red;
    border-color: $red;
    text-transform: none;
    font-size: 14px;
    border-radius: 100px;
    padding: 10px 0px;
    font-family: OpenSansBold;
  }
}

@media screen and (max-width: $break_point_xs) {
  .main {
    .timerPredefineButtons {
      button {
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: $break_point_xxs) {
  .main {
    .timerPredefineButtons {
      button {
        font-size: 10px;
        padding: 0;
      }
    }
  }

  .focusPaper {
    padding-left: 10px;
    padding-right: 10px;
  }
}
