@import '../../styles/colors.scss';
@import '../../styles/media.scss';

.wrapper {
  background-color: $bg_color;
  font-family: OpenSansSemiBold;
  color: $black;
  font-size: 18px;
  display: flex;
  flex: 1;
  background-image: url('../../assets/images/sign-up-bg-eye.svg');
  background-size: 25%;
  background-repeat: no-repeat;
  background-position: 100% 50%;
  flex-direction: row;
  justify-content: center;
  height: calc(100vh - 185px);
  .heading {
    margin: 0;
    font-size: 36px;
  }

  .boldText {
    font-family: OpenSansBold;
  }

  .infoBlock {
    display: flex;
    flex-direction: column;
    width: 440px;
    height: 350px;
    padding-top: 25px;
  }
  .innerInfoBlock {
    padding-right: 15px;
  }
  .blockItem {
    display: flex;
    flex-direction: row;
    & > img {
      margin-left: 25px;
    }
    & > p {
      margin-left: 20px;
    }
  }
}

@media screen and (max-width: $break_point_md) {
  .wrapper {
    .infoBlock {
      display: none;
    }
  }
}

@media screen and (max-width: $break_point_sm) {
  .wrapper {
    height: auto;
  }
}
