@import '../../../styles/colors.scss';
@import '../../../styles/media.scss';

.popper {
  margin-left: 10px;
  .tooltip,
  .tooltipArrow {
    color: $white;
    background-color: $black;
    padding: 14px 12px;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  .arrow {
    color: $black;
    font-size: 16px;
  }
}

.button {
  height: 100%;
  width: 100%;
  margin: 10px;
  background-color: $white;
  border-radius: 12px;
  border: 1px solid $button_border;
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
  .popover {
    position: relative;
    border: 1px solid red;
    width: 27px;
    height: 27px;
    left: 150px !important;
    transform: translateX(20%);
    color: blue;
    padding: 10px;
    p {
      text-transform: capitalize;
    }
  }

  .wrapper {
    width: 100%;
    height: 100%;
  }
  button {
    &:hover {
      opacity: 1;
      background-color: transparent;
      transition: none;
    }
  }
}

.buttonActive {
  background-color: $black;
  opacity: 1;
  button {
    z-index: 1;
    &.active {
      svg {
        z-index: 1;
        > g > path {
          fill: $white;
        }
      }
      path {
        fill: $white;
      }
    }
    &:before {
      position: absolute;
      top: 14px;
      right: -4px;
      content: '';
      width: 20px;
      height: 20px;
      transform: rotate(45deg);
      border-radius: 3px;
      background-color: $black;
      z-index: 0;
    }
  }
}

.hideActiveArrow {
  button {
    &:before {
      display: none;
    }
  }
}

.homeButton {
  height: 46px;
  width: 46px;
  min-width: none;
  box-sizing: border-box;
  button {
    height: 100%;
    width: 100%;
    padding: 0;
    span {
      height: 100%;
      width: 100%;
    }
    &:hover {
      background-color: transparent;
    }
    &:active {
      background-color: transparent;
    }
  }
}

@media screen and (max-width: $break_point_lg) {
  .button {
    opacity: 1;
  }
}

@media screen and (max-width: $break_point_sm) {
  .popper {
    margin: 0;
    .tooltip {
      margin-left: 20px;
    }
  }
  .homeButton {
    display: none !important;
  }
}
