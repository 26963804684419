@import '../../../../../styles/colors.scss';
@import '../../../../../styles/media.scss';

:root {
  --rulerTopColor: #000;
  --rulerBottomColor: #000;
}

.colorPreview {
  width: 22px;
  height: 22px;
  border-radius: 8px;
  position: relative;
}

.colorRulerTopPreview {
  background-color: var(--rulerTopColor);
}

.colorRulerBottomPreview {
  background-color: var(--rulerBottomColor);
}

.colorPicker {
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 999;
}

.topDivider,
.bottomDivider {
  margin: 0;
}
.RulerToolBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 8px 0;
  .title {
    font-size: 14px;
    font-weight: 600;
    padding: 7px 0;
  }
  .control {
    text-align: right;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    > span {
      box-shadow: 0px 0px 6px 0px #1f29370d;
      > span:first-child {
        box-shadow: 0px 0px 6px 0px #1f29370d;
      }
    }
  }
}

.sliderBlock {
  display: flex;
  flex-direction: column;
  .sliderTitle {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    padding: 7px 0;
    .rulerOpacityInput {
      font-size: 14px;
      font-weight: 600;
      margin: 4px 0 4px 15px;
      padding: 0 3px;
      width: 35px;
      height: 24px;
      border-radius: 4px;
      border: solid 1px #c5c5c5;
      background-color: #ffffff;
      input {
        text-align: center;
        padding: 8px 0 7px;
      }
      input[type='number']::-webkit-inner-spin-button,
      input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none !important;
        margin: 0;
      }
    }
  }
}

.undoResetButtons {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 0px;
    margin: 10px 0 12px;
    cursor: pointer;
    height: 30px;
    &:first-child {
      border-radius: 0;
      color: $red;
      display: flex;
      font-size: 1rem;
      text-transform: capitalize;
      font-weight: 600;
      font-family: 'MontserratSemiBold';
      letter-spacing: 0.05px;
      position: relative;
      height: 100%;
      align-items: center;
    }
    &:hover {
      background-color: transparent;
    }
  }
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paper {
  background-color: #d9d9d9;
  opacity: 1;
  padding: 40px 40px 30px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
  position: relative;
  .closeIcon {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
  }
  .titleWrapper {
    margin: 0px auto 30px;
    max-width: 300px;
    > h3 {
      font-family: 'OpenSansSemiBold';
      font-size: 18px;
      line-height: 20px;
      letter-spacing: 0.05px;
      margin: 0;
    }
  }

  &:focus {
    outline: none;
  }
  .actions {
    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: space-between;
    .buttonWrapperMargin {
      margin-right: 12px;
    }
  }
}
