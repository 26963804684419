@font-face {
  font-family: 'ComicSans';
  src: url('./assets/fonts/ComicNeue-Regular.ttf');
}
@font-face {
  font-family: 'ComicSansBold';
  src: url('./assets/fonts/ComicNeue-Bold.ttf');
}
@font-face {
  font-family: 'OpenDyslexicRegular';
  src: url('./assets/fonts/OpenDyslexic3-Regular.ttf');
}
@font-face {
  font-family: 'OpenDyslexicBold';
  src: url('./assets/fonts/OpenDyslexic3-Bold.ttf');
}
@font-face {
  font-family: 'GothamMedium';
  src: url('./assets/fonts/Gotham-Medium.ttf');
}
@font-face {
  font-family: 'GothamBold';
  src: url('./assets/fonts/Gotham-Bold.ttf');
}
@font-face {
  font-family: 'GothamLight';
  src: url('./assets/fonts/Gotham-Light.ttf');
}

@font-face {
  font-family: 'OpenSansBold';
  src: url('./assets/fonts/OpenSans-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSansSemiBold';
  src: url('./assets/fonts/OpenSans-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSansMedium';
  src: url('./assets/fonts/OpenSans-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'OpenSansRegular';
  src: url('./assets/fonts/OpenSans-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'OpenSansLight';
  src: url('./assets/fonts/OpenSans-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'MontserratLight';
  src: url('./assets/fonts/Montserrat-Light.ttf');
  font-weight: 300;
}
@font-face {
  font-family: 'MontserratRegular';
  src: url('./assets/fonts/Montserrat-Regular.ttf');
  font-weight: 400;
}
@font-face {
  font-family: 'MontserratMedium';
  src: url('./assets/fonts/Montserrat-Medium.ttf');
  font-weight: 600;
}
@font-face {
  font-family: 'MontserratSemiBold';
  src: url('./assets/fonts/Montserrat-SemiBold.ttf');
  font-weight: 700;
}
@font-face {
  font-family: 'MontserratBold';
  src: url('./assets/fonts/Montserrat-Bold.ttf');
  font-weight: 800;
}

.App {
  p,
  a,
  span {
    font-family: OpenSansMedium, sans-serif;
  }
}
