@import '../../styles/colors.scss';
@import '../../styles/media.scss';

.home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: OpenSansRegular;
  background-color: $bg_color;
  main {
    padding: 25px 10vw 25px 10vw;

    .buttonWrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex: 1;
      .button {
        cursor: pointer;
        border-radius: 100px;
        color: $white;
        padding-left: 35px;
        padding-right: 35px;
        background-color: $purple;
        &:hover {
          background-color: $light_purple;
        }
        p {
          font-size: 18px;
          font-family: OpenSansBold;
        }
      }
    }
    .errorBlock {
      justify-content: center;
      align-items: center;
      display: flex;
    }
    .bigLetter {
      font-size: 128px;
      font-family: OpenSansBold;
      line-height: 174px;
    }
    .image {
      margin-left: 15px;
      margin-right: 25px;
    }
    .errorText {
      color: $grey_text;
      font-size: 18px;
      text-align: center;
      font-family: OpenSansBold;
    }
  }
}

@media screen and (max-width: $break_point_xs) {
  .home {
    main {
      .bigLetter {
        font-size: 100px;
      }
      .buttonWrapper {
        margin-top: 38px;
        .button {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}
@media screen and (max-width: $break_point_xxs) {
  .home {
    main {
      padding: 125px 16px 125px 16px;
      .bigLetter {
        font-size: 100px;
      }
    }
  }
}
