.contextMenu {
  overflow: visible;
  .divider {
    background-color: #eeeeed;
    height: 2px;
  }
  .arrow {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #f7f4ec;
    transform: rotate(45deg);
    top: -5px;
    left: 17px;
  }
}

.loader {
  margin: 40px auto;
  display: block !important;
}

.dictionaryTTS {
  color: #fff;
  background: #0073ff;
  cursor: pointer;
  padding: 1px;
  border-radius: 8px;
}

.dictionaryWord {
  vertical-align: top;
  margin-left: 8px;
}

.dictionaryMainDefinition {
  margin-top: 15px;
  font-size: 12px;
}

.dictionaryPronunciation {
  margin-left: 30px;
  margin-top: -5px;
  font-style: italic;
  color: #0073ff;
  font-size: 12px;
}

.dictionaryError {
  font-size: 12px;
  color: #ff0000;
}

.dictionaryClose {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  color: #b0bdcc;
}
