@import '../../../../../styles/colors.scss';
@import '../../../../../styles/media.scss';

.titleBlock {
  display: grid;
  grid-template-rows: 30px;
  grid-template-columns: 35px 1fr 50px;
  font-size: 14px;
  font-weight: 600;
  .icon {
    padding: 3px 0;
    width: 20px;
    height: 20px;
  }
  .sliderTitle {
    padding: 5px 0;
    font-family: 'OpenSansRegular';
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.04px;
    font-weight: 600;
  }
  .wordSpacingInput {
    font-size: 14px;
    font-weight: 600;
    margin: 4px 0 4px 9px;
    padding: 0 3px;
    width: 41px;
    height: 26px;
    border-radius: 4px;
    // box-shadow: inset -1px 3px 6px 0 rgba(0, 0, 0, 0.12);
    border: solid 1px #bfbfbf;
    background-color: #ffffff;
    input {
      text-align: center;
      padding: 8px 0 7px;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none !important;
      margin: 0;
    }
  }
}

.slider {
  margin: 0;
  height: 11px !important;
  display: flex;
  align-items: center;
}

.track {
  height: 4px !important;
  border-radius: 12px !important;
  background-image: $purple;
}

.rail {
  height: 4px !important;
  border-radius: 12px !important;
  color: #ededed;
}

.thumb {
  background-color: $purple !important;
  border: solid 2px #fefefe !important;
  height: 24px !important;
  width: 24px !important;
  margin-top: -11px !important;
  box-shadow: none;
  &:hover {
    box-shadow: none !important;
  }
  &:active {
    box-shadow: none !important;
  }
}

@media screen and (max-width: $break_point_sm) {
  .slider {
    padding: 13.5px 0 !important;
  }
}
