footer {
  z-index: 2;
  padding: 0 10vw 0 10vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  background-color: #fff;
  //box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  color: #000;
  z-index: 2;
  p,
  a {
    font-family: Arial, sans-serif;
  }
  .faq a {
    padding: 0 10px 0 10px;
    text-decoration: none;
    color: #000;
  }
}

.cookiePopover{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 660px;
  padding: 35px;
  margin-left: -30px;
  margin-top: -10px;
  border-radius: 8px !important;
  .cookieIcon{
    width: 15%;
    svg{
      width: 70px;
      height: 70px;
    }
  }
  .cookieText{
    width: 50%;
  }
  .cookieAccept{
    width: 35%;
    text-align: end;
    button{
      width: 110px;
      height: 50px;
      background-color: #0073ff;
      text-transform: none;
      font-size: 16px;
    }
  }
}
