.home {
  margin: 0 auto;
  min-height: 100vh;
  font-family: 'Open Sans', sans-serif;
  position: relative;
  .emptyComponent {
    height: 80vh;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.homeSingle {
  margin: 0 auto;
  height: 100vh;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-family: 'Open Sans', sans-serif;
  .emptyComponent {
    height: 100vh;
  }
}
