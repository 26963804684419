@import '../../styles/colors.scss';
@import '../../styles/media.scss';

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: $bg_color;
  position: relative;
  z-index: 10;
  .shadowWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 22px 40px;
    .mobileBurger {
      display: none;
    }
    .logo {
      justify-content: center;
      display: flex;
      flex-direction: row;
      align-items: center;
      .eye {
        height: 47px;
        width: 61px;
      }
      .logoIcon {
        height: 41px;
        width: 98.5px;
      }
    }
    .withoutAcc {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      a {
        text-decoration: none;
        text-transform: none;
      }
      .buttonMarginContainer {
        margin: 0 20px;
      }
      .buttonContainer {
      }
      .buttonContainerSingle {
        margin: 0 20px;
      }
    }
  }
}
.avatarWrapper {
  display: flex;
  flex-direction: row;
  .searchWrapper {
    margin-right: 24px;
    min-width: 256px;
  }
  & > div {
    display: flex;
    flex-direction: row;
    .avatar {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
      border: solid 3px #ffffff;
      border-radius: 50%;
      background-color: #fff;
      z-index: 500;
      svg {
        height: 24px;
        width: 24px;
        title {
          content: '';
        }
      }
      img {
        width: 100%;
        height: auto;
        border-radius: 50%;
      }
    }
    .authButtons {
      grid-area: b;
      width: 100%;
      font-size: 16px;

      & > div {
        display: flex;
        justify-content: flex-end;
        padding-right: 10vw;
        margin-top: 10px;
      }
      a {
        border-radius: 8px;
        height: 40px;
        text-transform: none;
        margin-left: 15px;
        button {
          background-color: transparent;
          border: 1px solid #000;
          padding: 5px 20px 5px 20px;
        }
      }
      .loginButton {
        border: solid 1px #000000;
        margin-left: 30px;
      }
    }
  }
}
.filtersWrapper {
}

.userPaper {
  margin-top: 60px;
  min-width: 440px;
  position: absolute;
}

.modalWrapper {
  background-color: $bg_black_color_opacity;
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  position: fixed;
}

.modal {
  background-color: $bg_white_color;
  position: absolute;
  right: 40px;
  top: 105px;
  border-radius: 12px;
  width: 500px;
  padding: 23px 25px 35px 25px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
}

.menuTitle {
  margin: 0;
  font-size: 36px;
  line-height: 49px;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  color: $black;
  span {
    font-weight: 700;
  }
}
.logoutButtonWrapper {
  width: 100%;
  border-radius: 100px;
  margin-top: 24px;
  color: $black;
  font-family: OpenSansBold;
}
.logoutButton {
  width: 100%;
}

@media screen and (max-width: $break_point_sm) {
  .header {
    z-index: 1302;
    position: fixed;
    width: 100%;
    .shadowWrapper {
      box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
      padding: 8px 20px 7px 20px;
      z-index: 2;
      height: 45px;
      .avatarWrapper {
        display: none;
      }
      .mobileBurger {
        display: flex;
        cursor: pointer;
        padding: 10px;
      }
      .authButtons {
        display: none;
      }
      .logo {
        .eye {
          height: 35px;
          width: 45px;
        }
        .logoIcon {
          height: 34px;
          width: 82px;
        }
      }
    }
  }
  .headerWhite {
    background-color: $white;
  }
}
