@import '../../styles/colors.scss';
@import '../../styles/media.scss';

.main {
  grid-area: m;
  border-radius: 8px;
  padding: 25px 10vw 25px 10vw;
  background-color: $bg_color;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .privacy {
    font-size: 18px;
    padding: 10px 0 0;
    line-height: 24px;
    > p {
      margin: 0;
      font-family: 'Open Sans', sans-serif;
    }
    .bold {
      font-weight: bold;
      margin: 16px 0 0;
    }
    .underline {
      text-decoration: underline;
    }
  }
  .title {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    .posted {
      margin: 0;
      font-size: 14px;
      font-family: 'Open Sans', sans-serif;
      color: $light_grey;
      font-weight: 400;
    }
    h1 {
      font-size: 36px;
      margin: 0;
      line-height: 49px;
      font-weight: 700;
    }
  }
}

@media screen and (max-width: $break_point_sm) {
  .main {
    padding: 95px 15px 0;
    box-shadow: none;
    .privacy {
      font-size: 16px;
      line-height: 20px;
    }
  }
}
