@import '../../../styles/colors.scss';
@import '../../../styles/media.scss';

.mainButton {
  width: 100%;
  min-width: auto;
  #button {
    text-transform: none;
    text-decoration: none;
    box-shadow: none;
    padding: 15px 32px;
    border-radius: 100px;
    font-size: 18px;
    line-height: 20px;
    height: 56px;
    width: 100%;
    min-width: auto;
    span {
      font-family: OpenSansSemiBold;
      font-weight: 700;
    }
    #lightText {
      font-family: OpenSansRegular;
      margin-left: 5px;
      font-weight: 400;
    }
  }

  .ghostButton {
    border: 2px solid $black;
    background-color: transparent;
    color: $black;
    &:hover {
      background-color: $grey_hover;
    }
  }

  .secondaryButton {
    background-color: $black;
    color: $white;
    &:hover {
      background-color: $light_black;
    }
  }

  .primaryButton {
    background-color: $purple;
    color: $white;
    &:hover {
      background-color: $light_purple;
    }
  }
}
