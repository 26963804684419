@import '../../styles/colors.scss';
@import '../../styles/media.scss';

.rulerArrowWrapper {
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  .rulerArrowContainer {
    position: relative;
    width: 100%;
    height: 100%;
    .rulerArrow {
      position: absolute;
      cursor: var(--rulerCursor);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 48px;
      height: calc(var(--lineHeight) + var(--lineHeight) * 0.4);
      border: 1px solid $button_border;
      right: 0px;
      top: calc(var(--rulerBottomHeight) - var(--lineHeight) + 2px);
      background-color: $white;
      width: 32px;
      box-sizing: border-box;
      z-index: 3;
      transform: translate(0%, 0);
      .rulerIconsWrapper {
        position: relative;
        height: 100%;
        width: 100%;
        .rulerIconTop {
          pointer-events: visible;
          width: 32px;
          box-sizing: border-box;
          background-color: $white;
          top: -12px;
          right: -1px;
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
          position: absolute;
          padding: 8px 7px 8px 7px;
          border-top: 1px solid $button_border;
          border-left: 1px solid $button_border;
          border-right: 1px solid $button_border;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            transform: rotate(90deg);
            height: 14px;
          }
        }
        .rulerIconBottom {
          pointer-events: visible;
          width: 32px;
          box-sizing: border-box;
          background-color: $white;
          border-bottom: 1px solid $button_border;
          border-left: 1px solid $button_border;
          border-right: 1px solid $button_border;
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
          bottom: -12px;
          right: -1px;
          position: absolute;
          padding: 6px 7px 6px 7px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            transform: rotate(270deg);
            height: 14px;
          }
        }
      }
    }
    .rightStringTop,
    .rightStringBottom {
      display: none;
    }
  }
}

@media screen and (max-width: $break_point_sm) {
  .rulerArrowWrapper {
    .rulerArrowContainer {
      .rulerArrow {
        height: calc(var(--lineHeight) + 22px);
        top: calc(var(--rulerBottomHeight) - var(--lineHeight) - 14px);
        .rulerIconsWrapper {
          .rulerIconTop {
            svg {
            }
          }
          .rulerIconBottom {
            svg {
            }
          }
        }
      }
      .rightStringTop {
        top: -8.3vh;
        right: 0;
        width: 32px;
        position: absolute;
        height: calc(var(--rulerTopHeight));
        display: block;
        background-color: var(--rulerTopColor);
        opacity: var(--rulerTopOpacity);
      }
      .rightStringBottom {
        top: 0;
        right: 0;
        width: 32px;
        position: absolute;
        height: 100%;
        display: block;
        background-color: var(--rulerBottomColor);
        opacity: var(--rulerBottomOpacity);
        top: var(--rulerBottomHeight);
      }
    }
  }
}
