$bg_color: #fffdf8;
$bg_color_opacity: #fffefd;
$bg_white_color: #fff;
$bg_black_color_opacity: rgba(0, 0, 0, 0.5);
$orange_main: #78bebe;
$green: #24d399;
$blue: #a7feee;
$purple: #6f8ef0;
$light_purple: #89a4f9;
$background_purple: #cdd6f4;
$white: #ffffff;
$black: #2d363b;
$light_black: #44535b;
$red: #ff8b8b;
$background: #efe9e2;
$green_main: #9fe96e;
$light_grey: #14141480;
$border_grey: #ededed;
$border_dark_grey: #c5c5c580;
$border_inputs: #c5c5c5;
$shadow_black: #0000000d;
$progress_grey: #dadada;
$hover_black: #00000026;
$grey_text: #666666;
$buttonBgColor: #6f8ef0;
$buttonBgColorHover: #89a4f9;
$grey_hover: #2d363b1a;
$button_border: #2d363b40;
$checkbox_color: #333333;
$black_with_opacity_ten: #eaebeb;
$hr_border_color: #979797;
$dark_blue_background: #11132e;
$grey_opacity_background: #dcdde0;
$white_opacity_background: #fffffe;
