@import '../../styles/colors.scss';
@import '../../styles/media.scss';

.burgerMenu {
  position: absolute;
  top: 100%;
  width: 100%;
  padding: 28px 16px;
  box-sizing: border-box;
  left: 0;
  background-color: $white;
  z-index: 1;
  height: 100vh;
  .menuTitle {
    margin: 0;
    font-size: 36px;
    line-height: 49px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    span {
      font-weight: 700;
    }
  }
  .searchWrapper {
    margin: 29px 0;
  }
  .linksWrapper {
    display: flex;
    flex-direction: column;
    > a {
      border-top: 0.5px solid rgba(45, 54, 59, 0.25);
      width: 100%;
      font-family: 'OpenSansBold';
      font-size: 17px;
      line-height: 20px;
      padding: 10px 0;
      text-align: center;
      color: $black;
      text-decoration: none;
      padding: 40px 0;
      &:last-child {
        border-bottom: 0.5px solid rgba(45, 54, 59, 0.25);
      }
    }
  }
  .logoutButton {
    margin-top: 24px;
    width: 100%;
    border-radius: 100px;
    border: 2px solid $black;
    padding: 17px 0;
    font-family: 'Open Sans';
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
    text-transform: none;
  }
  .loader {
    position: absolute;
    top: 30%;
    left: 43%;
  }
}
