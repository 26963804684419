@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&;600display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');

:root {
  --bodyOverflowY: overlay !important;
  --bodyScrollBehavior: auto;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-height: 100%;
  overflow-y: var(--bodyOverflowY);
  background-color: #f7f4ec;
}
html,
body {
  /* height: 100vh; */
  margin: 0px;
  overflow-x: hidden;
  scroll-behavior: var(--bodyScrollBehavior);
}
html {
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
