@import '../../styles/colors.scss';
@import '../../styles/media.scss';

aside {
  display: flex;
  flex-direction: column;
  position: relative;
  right: 0;
  width: 100%;
  & > div {
    position: fixed;
    left: 20px;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
  }
}
.aside {
  z-index: 3 !important;
  .homeWrapper {
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-top: 16px;
  }
  .buttonWrapper {
    border-radius: 8px;
    flex-direction: column;
    width: fit-content;
    display: flex;
    align-items: flex-end;
    z-index: 6;
    > a,
    > div {
      cursor: pointer;
      height: 46px;
      width: 46px;
      margin-bottom: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      > div {
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .mobileMenu {
    display: none;
    height: 48px;
    width: 48px;
    position: fixed;
    left: 16px;
    bottom: 84px;
    > div {
      margin: 0;
    }
  }
}

@media screen and (max-width: $break_point_md) {
  .aside {
    .buttonWrapper {
      -webkit-backdrop-filter: none;
      backdrop-filter: none;
    }
  }
}

@media screen and (max-width: $break_point_sm) {
  .aside {
    .buttonWrapper {
      -webkit-backdrop-filter: none;
      backdrop-filter: none;
      > a,
      > div {
        margin-bottom: 16px;
      }
    }
    .hidedButtonWrapper {
      display: none;
      > a,
      > div {
        display: none;
      }
    }
    > div {
      left: 16px;
      bottom: 67px;
      height: calc(100% - 87px);
    }

    .mobileMenu {
      display: flex;
      z-index: 3;
      bottom: 16px;
    }
    .mobileMenuActive {
      > div {
        background-color: $black;
        > button > span > svg > path {
          fill: $white;
        }
      }
    }
    .mobileHidePagination {
      display: none;
    }
  }
}
