@import '../../styles/colors.scss';
@import '../../styles/media.scss';

:root {
  --wallpaperColor: #f7f4ec;
  --fullScreen: 0;
}

.bookPage {
  background-color: $bg_color;
  display: flex;
  border-radius: 8px;
  height: 100%;

  div {
    flex: 1;
    padding: 25px 47px 25px 46px;
  }
}

.main {
  background-color: var(--wallpaperColor);
  display: grid;
  margin: 0 auto;
  grid-template-areas: 't m .';
  grid-template-columns: 3fr 6fr 3fr;
  grid-template-rows: 10fr;
  grid-gap: 0px;
  height: auto;
  min-height: 100vh;
  header {
    pointer-events: none;
    position: relative;
    z-index: 0;
    box-shadow: none;
    height: 80px;
    opacity: 0;
    > div {
      padding: 0px !important;
      height: 0px;
      > div {
        display: none !important;
        svg {
          display: none;
        }
      }
    }
    h1 {
      display: none;
    }
  }
  main {
    position: relative;
    grid-area: m;
  }
  aside {
    position: relative;
    z-index: 3;
    grid-area: t;
    z-index: 1;
  }
  footer {
    margin-top: 0.3vh;
    position: relative;
    z-index: 3;
    grid-area: none;
    box-shadow: none;
    height: 0;
  }
}

.focusMode {
  background-color: $dark_blue_background;
}

@media screen and (max-width: $break_point_sm) {
  .main {
    grid-template-columns: 0fr 10fr 0fr;
    grid-template-rows: 10fr;
  }
  .focusMode {
    background-color: $dark_blue_background;
  }
}
