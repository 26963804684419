.wrapper {
  & > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: none;
    &:before,
    &:after {
      display: none;
    }
    svg {
      fill: #000;
    }
    input {
      height: 100%;
      z-index: 1;
    }
  }
  .arrowWrapper {
    transform: rotate(270deg);
    top: calc(50% - 10px);
    color: rgba(0, 0, 0, 0.54);
    right: 5px;
    position: absolute;
    pointer-events: none;
    cursor: pointer;
    z-index: 0;
  }
}
