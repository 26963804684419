@import '../../../styles/colors.scss';
@import '../../../styles/media.scss';

.main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4px 4px 4px 21px;
  background-color: $white;
  border: 1px solid $border_dark_grey;
  width: 100%;
  border-radius: 300px;
  box-sizing: border-box;
  .search {
    cursor: pointer;
    height: 48px;
    width: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: $white;
    border-radius: 24px;
    box-shadow: 0 4px 20px 0 $shadow_black;
  }
  .input {
    border: none;
    border-radius: 300px;
    width: 78%;
    font-size: 18px;
    line-height: 20px;
    font-family: 'Open Sans', sans-serif;
    color: $black;
    &:placeholder-shown {
      opacity: 0.25;
    }
    &:focus {
      outline: none;
    }
  }
}
